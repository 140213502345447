import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import styled from "styled-components";
import { addPrepayment } from "../../store/prepayments";
import { addInvite } from "../../store/invite";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { createDeposits } from "../../utils/depositUtils";
import { RootState } from "../..";
import PageLoader from "../PageLoader";
import BackButton from "../baseComponents/BackButton";
import Button from "../baseComponents/Button";
import Container from "../baseComponents/Container";
import Div from "../baseComponents/Div";
import AutocompleteDropdown from "../baseComponents/AutocompleteDropdown";
import Dropdown from "../baseComponents/Dropdown";
import DateInput from "../baseComponents/DateInput";
import Row from "../baseComponents/Row";
import AddTenantCard from "./AddTenantCard";
import TenantItem from "./TenantItem";
import InviteSuccessMessage from "./InviteSuccessMessage";
import ErrorMessage from "../baseComponents/ErrorMessage";

import {
  Building,
  Prepayment,
  Invite,
  Unit,
  GenericObject,
  Option,
  DepositList,
  Tenant,
} from "../global/ModelInterfaces";
import { cssBreakpoints } from "../global/theme";
import Checkbox from "../baseComponents/Checkbox";

const StyledRow = styled(Row)`
  padding-bottom: 2rem;
  ${cssBreakpoints("margin-top", [{ sm: "2rem" }, { lg: "0rem" }])}
`;

const StyledHeader = styled.h1`
  font-weight: bold;
`;

const MonthToMonthText = styled(Row)`
  font-size: ${(props) => props.theme.font_size.bodySM};
  color: ${(props) => props.theme.colors.grey60};
`;

const InviteTenantView = () => {
  const dispatch = useAppDispatch();
  const { unitUUID } = useParams<GenericObject>();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [errors, setErrors] = useState<GenericObject>({
    depositStartDate: false,
    depositEndDate: false,
    selectedBuilding: false,
    selectedUnit: false,
  });
  const [success, setSuccess] = useState(false);
  const [depositCreationLoading, setDepositCreationLoading] = useState(false);
  const [selectedBuilding, setSelectedBuilding] = useState<
    Building | undefined
  >(undefined);

  const [selectedUnit, setSelectedUnit] = useState<Unit | null>(null);
  const [depositStartDate, setDepositStartDate] = useState<string | null>(null);
  const [depositEndDate, setDepositEndDate] = useState<string | null>(null);
  const [monthToMonth, setMonthToMonth] = useState<boolean>(false);
  const [tenantArray, setTenantArray] = useState<Array<Tenant>>([]);
  const [showAddTenant, setShowAddTenant] = useState(true);
  const [unitOptions, setUnitOptions] = useState<
    {
      label: string;
      identifier: string | undefined;
      id: string;
    }[]
  >([]);

  // All Buildings
  const buildingSlice: { loading: Boolean; objects: Building[] } =
    useAppSelector((state: RootState) => state.buildings);
  const loadingBuildings = buildingSlice.loading;

  // All Units
  const unitSlice: { loading: Boolean; objects: Unit[] } = useAppSelector(
    (state: RootState) => state.units
  );
  const loadingUnits = unitSlice.loading;

  useEffect(() => {
    if (unitUUID) {
      const unit = unitSlice.objects.find(
        (unit: Unit) => unit.uuid === unitUUID
      );
      if (unit) {
        setSelectedUnit(unit);
        const building = buildingSlice.objects.find((building: Building) => {
          return building.id === unit.building_id;
        });
        if (building) {
          setSelectedBuilding(building);
        }
      }
    }
  }, []);

  const buildingOptions = buildingSlice.objects.map((building) => {
    return {
      label: building.name,
      id: building.uuid,
    };
  });

  const addTenant = (tenant: Tenant) => {
    const updateTenants = [...tenantArray, tenant];
    setTenantArray(updateTenants);
    setShowAddTenant(false);
  };

  const onCancel = () => {
    setShowAddTenant(false);
  };

  const onDelete = (uuid: string) => {
    const tempArray = [...tenantArray];
    const updateArray = tempArray.filter((tenant) => tenant.uuid !== uuid);
    setTenantArray(updateArray);
    if (tenantArray.length === 1) {
      setShowAddTenant(true);
    }
  };

  const addTenantOnClick = () => {
    setShowAddTenant(true);
  };

  const buildingOnClick = (option: Option) => {
    clearError("selectedBuilding");
    const building = buildingSlice.objects.find(
      (building: Building) => building.uuid === option.id
    );
    if (building) {
      setSelectedBuilding(building);
      setSelectedUnit(null);
    }
  };

  useEffect(() => {
    if (selectedBuilding) {
      const units: Unit[] = unitSlice.objects.filter(
        (unit: Unit) => unit.building_id === selectedBuilding.id
      );
      const newUnitOptions = units.map((unit) => {
        return {
          label: unit.street_address,
          identifier: unit.address_2,
          id: unit.uuid,
        };
      });
      setUnitOptions(newUnitOptions);
    } else {
      setUnitOptions([]);
    }
  }, [selectedBuilding]);

  const unitOnClick = (option: Option | null) => {
    clearError("selectedUnit");
    if (!option) {
      setSelectedUnit(null);
    } else {
      let unit = unitSlice.objects.find(
        (unit: Unit) => unit.uuid === option.id
      );
      if (unit) {
        setSelectedUnit(unit);
      }
    }
  };

  const clearError = (key: string) => {
    let tmpErrors = errors;
    tmpErrors[key] = false;
    setErrors(tmpErrors);
    setErrorMessage(null);
  };

  const validateForm = () => {
    let validForm = true;
    let tmpErrors = errors;
    if (!depositStartDate) {
      tmpErrors = { ...tmpErrors, depositStartDate: true };
      validForm = false;
    }
    if (!depositEndDate && !monthToMonth) {
      tmpErrors = { ...tmpErrors, depositEndDate: true, monthToMonth: true };
      validForm = false;
    }
    if (!selectedBuilding) {
      tmpErrors = { ...tmpErrors, selectedBuilding: true };
      validForm = false;
    }
    if (!selectedUnit) {
      tmpErrors = { ...tmpErrors, selectedUnit: true };
      validForm = false;
    }
    return { valid: validForm, validationErrors: tmpErrors };
  };

  const checkDeposits = (tenantArray: Tenant[]) => {
    tenantArray.forEach((tenant: GenericObject) => {
      tenant.depositList = tenant.depositList.filter((deposit: DepositList) => {
        return !!deposit.amount;
      });
    });
    return tenantArray;
  };

  const mapNewPrepaymentsToTenants = (prepaymentArray: Prepayment[]) => {
    return prepaymentArray.map((prepayment) => {
      const newDepositArray = Object.entries(prepayment.deposits).map(
        (value, index, extra: GenericObject) => {
          const deposit = value[1];
          return {
            amount: deposit.amount,
            id: index,
            type: deposit.type,
          };
        }
      );
      return {
        name: prepayment.tenant_name,
        email: prepayment.tenant_email,
        depositList: newDepositArray,
        feeList: prepayment.fees,
        uuid: prepayment.uuid,
      };
    });
  };

  const sendInvites = () => {
    setErrorMessage("");
    let tempTenantArray = tenantArray;
    tempTenantArray = checkDeposits(tempTenantArray);
    const tenantInfo = {
      tenants: tempTenantArray,
      deposit_start_date: depositStartDate,
      deposit_end_date: depositEndDate,
      month_to_month: monthToMonth,
      unit_uuid: selectedUnit?.uuid,
    };
    const { valid, validationErrors } = validateForm();
    if (valid) {
      setDepositCreationLoading(true);
      createDeposits(tenantInfo)
        .then(({ new_prepayments, invites, errors }) => {
          new_prepayments.forEach((prepayment: Prepayment) => {
            dispatch(addPrepayment(prepayment));
          });
          invites.forEach((invite: Invite) => {
            dispatch(addInvite(invite));
          });
          const newTenantArray = mapNewPrepaymentsToTenants(new_prepayments);
          if (errors.length > 0) {
            setErrorMessage(errors);
          }
          setTenantArray(newTenantArray);
          if (newTenantArray.length > 0) {
            setSuccess(true);
          }
        })
        .catch((e) => {
          setErrorMessage(`${e.message}`);
        })
        .finally(() => {
          setDepositCreationLoading(false);
        });
    } else {
      setErrors(validationErrors);
      if (validationErrors.depositStartDate) {
        setErrorMessage("Deposit start date must be filled");
      } else if (
        validationErrors.depositEndDate &&
        validationErrors.monthToMonth
      ) {
        setErrorMessage(
          "Deposit end date must be filled or you must confirm that this lease is month to month by checking the box."
        );
      } else if (validationErrors.selectedBuilding) {
        setErrorMessage("A building must be selected");
      } else if (validationErrors.selectedUnit) {
        setErrorMessage("Unit must be selected");
      } else {
        setErrorMessage("Please fill in required fields");
      }
    }
  };

  if (loadingBuildings || loadingUnits) {
    return (
      <Div>
        <PageLoader />
      </Div>
    );
  }
  return (
    <Container>
      <BackButton text="Go back" />
      <StyledRow justifyContent="center">
        <Div width={{ default: 1 }} alignItems="center">
          {success ? (
            <Div width={{ sm: 1, md: 1 / 3, lg: 1 / 3 }}>
              <InviteSuccessMessage
                selectedUnit={selectedUnit}
                tenantArray={tenantArray}
                errors={errorMessage}
              />
            </Div>
          ) : (
            <Div width={{ sm: 1, md: 1, lg: 6 / 12 }}>
              <Div>
                <StyledHeader>Invite New Tenants</StyledHeader>
              </Div>
              <Row justifyContent="center">
                <Div width={{ default: 1 }}>
                  <Dropdown
                    name="Building"
                    options={buildingOptions}
                    placeholder="Select a Building"
                    onClick={buildingOnClick}
                    value={
                      selectedBuilding
                        ? {
                            label: selectedBuilding.name,
                            id: selectedBuilding.uuid,
                          }
                        : null
                    }
                    error={errors.selectedBuilding}
                  />
                </Div>
              </Row>
              {!!selectedBuilding && (
                <Row justifyContent="center">
                  <Div width={{ default: 1 }}>
                    <AutocompleteDropdown
                      name="Unit Number"
                      options={unitOptions}
                      placeholder="Enter Unit Number"
                      onClick={unitOnClick}
                      value={
                        selectedUnit
                          ? {
                              label: selectedUnit.street_address,
                              id: selectedUnit.uuid,
                            }
                          : null
                      }
                      error={errors.selectedUnit}
                    />
                  </Div>
                </Row>
              )}
              {!!selectedUnit && (
                <>
                  {tenantArray.map((tenant: Tenant) => {
                    return (
                      <div key={tenant.uuid}>
                        <TenantItem
                          tenant={tenant}
                          onDelete={onDelete}
                          clickable={false}
                          showDropDownCaret={true}
                        />
                      </div>
                    );
                  })}
                  {showAddTenant ? (
                    <AddTenantCard
                      onAdd={addTenant}
                      onCancel={onCancel}
                      showCancel={tenantArray.length > 0}
                      unitState={selectedUnit?.state}
                    />
                  ) : (
                    <Row>
                      <Div>
                        <Button
                          text={"Add Tenant"}
                          type={"secondary"}
                          onClick={addTenantOnClick}
                        ></Button>
                      </Div>
                    </Row>
                  )}
                  <Row justifyContent="center">
                    <Div width={{ sm: 1, md: 1 / 2, lg: 1 / 2 }}>
                      <DateInput
                        label={"Lease Start Date"}
                        onChange={(date: string) => {
                          setDepositStartDate(date);
                          clearError("depositStartDate");
                        }}
                        error={errors.depositStartDate}
                      />
                    </Div>
                    <Div width={{ sm: 1, md: 1 / 2, lg: 1 / 2 }}>
                      <DateInput
                        label={"Lease End Date"}
                        onChange={(date: string) => {
                          setDepositEndDate(date);
                          clearError("depositEndDate");
                        }}
                        error={errors.depositEndDate}
                      />
                    </Div>
                  </Row>
                  <MonthToMonthText
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <Div>Lease has no End Date and is month to month.</Div>
                    <Checkbox
                      value={monthToMonth}
                      onChange={() => {
                        setMonthToMonth((monthToMonth) => !monthToMonth);
                        setDepositEndDate("");
                      }}
                    />
                  </MonthToMonthText>
                  <Row justifyContent="center">
                    <Div width={{ default: 1 }}>
                      <Button
                        text={"Send Invites"}
                        type={"primary"}
                        onClick={sendInvites}
                        loading={depositCreationLoading}
                        disabled={
                          tenantArray.length <= 0 ||
                          showAddTenant ||
                          !validateForm().valid
                        }
                      ></Button>
                    </Div>
                  </Row>
                </>
              )}
              <Row>
                <Div>
                  {errorMessage && (
                    <ErrorMessage>Error: {errorMessage}</ErrorMessage>
                  )}
                </Div>
              </Row>
            </Div>
          )}
        </Div>
      </StyledRow>
    </Container>
  );
};

export default InviteTenantView;
