import { useState } from "react";
import styled from "styled-components";
import Div from "../../../components/baseComponents/Div";
import Row from "../../../components/baseComponents/Row";
import Dropdown from "../../../components/baseComponents/Dropdown";
import RentManagerCompanyForm from "./CreateRentManagerCompanyForm";
import { Option } from "../../../components/global/ModelInterfaces";
import integrationType from "../../../enums/integrationType";

const StyledInstructions = styled.span`
  font-size: ${(props) => props.theme.font_size.headlineSM};
`;

const CreateIntegration = () => {
  const [selectedIntegration, setSelectedIntegration] = useState<Option | null>(
    null
  );

  const integrationOptions: Array<Option> = [
    { label: "Rent Manager", id: integrationType.RENT_MANAGER.toString() },
    { label: "Yardi", id: integrationType.YARDI.toString() },
  ];
  const integrationOnSelect = (option: Option) => {
    setSelectedIntegration(option);
  };
  return (
    <Row justifyContent="center">
      <Div width={{ default: 8 / 12 }}>
        <Row mt={{ default: 3 }} mb={{ default: 3 }}>
          <StyledInstructions>
            Add an integration to this company.
            <br />
            Start by selecting an integration type from the dropdown below.
          </StyledInstructions>
        </Row>
        <Row>
          <Div width={{ default: 1 }}>
            <Dropdown
              name="Integration Type"
              options={integrationOptions}
              placeholder="Select an Integration to continue."
              onClick={integrationOnSelect}
              value={
                selectedIntegration
                  ? {
                      label: selectedIntegration.label,
                      id: selectedIntegration.id,
                    }
                  : null
              }
            />
          </Div>
        </Row>
        {selectedIntegration &&
          Number(selectedIntegration.id) == integrationType.RENT_MANAGER && (
            <Row>
              <RentManagerCompanyForm />
            </Row>
          )}
      </Div>
    </Row>
  );
};

export default CreateIntegration;
