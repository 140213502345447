import { useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import styled from "styled-components";
import Div from "../../baseComponents/Div";
import Row from "../../baseComponents/Row";
import PageLoader from "../../PageLoader";
import SecurityDepositPayment from "./SecurityDepositPayment";
import SecurityDepositPaymentMonthly from "./SecurityDepositPaymentMonthly";
import useGetBankAccount from "../../../utils/useGetBankAccount";
import useCheckFinancials from "../../../utils/useCheckFinancials";
import PaymentChoice from "./PaymentChoice";
import PaymentPlanInfoModal from "./PaymentPlanInfoModal";
import ConfusedCoin from "../../../images/ConfusedCoin";
import { GenericObject } from "../../global/ModelInterfaces";
import useGetCurrentPrepayment from "../../../utils/useGetCurrentPrepayment";
const NavigationRow = styled(Row)`
  margin-bottom: 1rem;
`;

const SecurityDepositPaymentSubstep = () => {
  const { prepaymentUUID } = useParams<GenericObject>();
  const { financialInfo, loadingFinancialInfo, financialInfoError } =
    useCheckFinancials();
  const { bankAccount, loadingBankAccounts } = useGetBankAccount();

  const [paymentPlanChosen, setPaymentPlanChosen] = useState<boolean>(false);
  const [payInFullChosen, setPayInFullChosen] = useState<boolean>(false);
  const [paymentPlanModalOpen, setPaymentPlanModalOpen] =
    useState<boolean>(true);

  const { prepayment } = useGetCurrentPrepayment(prepaymentUUID);

  const securityDeposit = prepayment.deposits.security_deposit;

  const closePaymentPlanModal = () => {
    setPaymentPlanModalOpen(false);
  };

  const canPayMonthly = () => {
    return (
      !!securityDeposit &&
      financialInfo &&
      financialInfo.paymentPlanApproval.is_approved &&
      prepayment.tenant_can_pay_monthly
    );
  };

  if (loadingBankAccounts || loadingFinancialInfo) {
    return (
      <Div>
        <PageLoader />
      </Div>
    );
  }

  if (!securityDeposit) {
    return <Redirect to="/404" />;
  }

  if (!financialInfo) {
    return (
      <Div alignItems="center">
        <ConfusedCoin />
        {financialInfoError}
      </Div>
    );
  }

  if (!paymentPlanChosen && !payInFullChosen && canPayMonthly()) {
    return (
      <>
        <PaymentChoice
          bankAccount={bankAccount}
          choosePayInFull={() => {
            setPayInFullChosen(true);
          }}
          choosePaymentPlan={() => setPaymentPlanChosen(true)}
        />
        <PaymentPlanInfoModal
          showModal={paymentPlanModalOpen}
          closeModal={closePaymentPlanModal}
          paymentPlanApproval={financialInfo.paymentPlanApproval}
          showPaymentPlan={() => setPaymentPlanChosen(true)}
        />
      </>
    );
  }

  if (paymentPlanChosen && canPayMonthly()) {
    return (
      <Div width={{ default: 1 }}>
        <SecurityDepositPaymentMonthly
          onBack={() => {
            setPaymentPlanChosen(false);
          }}
          financialInfo={financialInfo}
        />
      </Div>
    );
  }
  return (
    <Div>
      <NavigationRow justifyContent="space-between">
        {canPayMonthly() && (
          <a
            onClick={() => {
              setPayInFullChosen(false);
              setPaymentPlanChosen(false);
            }}
          >
            Back
          </a>
        )}
      </NavigationRow>
      <SecurityDepositPayment
        financialInfo={financialInfo}
        financialInfoLoading={loadingFinancialInfo}
      />
    </Div>
  );
};

export default SecurityDepositPaymentSubstep;
