import axios from "axios";
import { GenericObject } from "../../components/global/ModelInterfaces";
import { rentManagerSecurityDepositsHeldHeaders } from "./rentManagerUtils";

export const retrieveCompanyByUUID = (
  uuid: string,
  successCallback: Function,
  failureCallback: Function
) => {
  const url = "/nucleus/api/retrieve-company-by-uuid";

  axios
    .post(url, {
      uuid: uuid,
    })
    .then((resp) => {
      const data = resp.data;
      successCallback(data);
    })
    .catch((error) => {
      failureCallback(error.data);
    });
};

export const retrieveCompanyList = (
  successCallback: Function,
  failureCallback: Function
) => {
  const url = "/nucleus/api/retrieve-company-list";

  axios
    .post(url)
    .then((resp) => {
      const data = resp.data;
      successCallback(data.companies);
    })
    .catch((error) => {
      failureCallback(error.data);
    });
};

export const inviteLandlordToCompany = (
  successCallback: Function,
  failureCallback: Function,
  companyUUID: number,
  sendEmail: boolean,
  isAdmin: boolean,
  email: string,
  firstName: string,
  lastName: string
) => {
  const url = "/nucleus/api/invite-landlord-to-company";

  axios
    .post(url, {
      company_uuid: companyUUID,
      email: email,
      first_name: firstName,
      last_name: lastName,
      send_email: sendEmail,
      is_admin: isAdmin,
    })
    .then((resp) => {
      const data = resp.data;
      successCallback(data);
    })
    .catch((error) => {
      failureCallback(error.data);
    });
};

export const changeCompanySettings = (
  updatedSetting: Object,
  successCallback: Function,
  failureCallback: Function
) => {
  axios
    .post("/nucleus/api/change-company-settings", updatedSetting)
    .then((res) => {
      const data = res.data;
      successCallback(data);
    })
    .catch((err) => {
      failureCallback(err);
    });
};

export const createCompany = (
  name: string,
  streetAddress: string,
  city: string,
  state: string,
  zipCode: string,
  successCallback: Function,
  failureCallback: Function
) => {
  const url = "/nucleus/api/create-company";

  axios
    .post(url, {
      name: name,
      street_address: streetAddress,
      city: city,
      state: state,
      zip_code: zipCode,
    })
    .then((resp) => {
      const data = resp.data;
      successCallback(data);
    })
    .catch((error) => failureCallback(error.data.error));
};

export const getSecurityDepositsHeld = (
  requestInfo: Object,
  successCallback: Function,
  failureCallback: Function
) => {
  const url = "/nucleus/api/rent-manager/get-security-deposits-held";
  axios
    .post(url, requestInfo)
    .then((res) => {
      successCallback(res.data);
    })
    .catch((err) => {
      failureCallback(err);
    });
};

export const downloadSecurityDepositHeldAsCsv = (
  values: Array<GenericObject>,
  successCallback: Function,
  failureCallback: Function
) => {
  const url = "/nucleus/api/rent-manager/download-as-csv";

  const requestInfo = {
    headers: rentManagerSecurityDepositsHeldHeaders,
    values: values,
  };

  axios
    .post(url, requestInfo)
    .then((res) => {
      successCallback(res.data);
    })
    .catch((err) => {
      failureCallback(err.data);
    });
};
