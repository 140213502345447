import styled from "styled-components";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useAppDispatch } from "../../store/hooks";
import Div from "../../components/baseComponents/Div";
import Row from "../../components/baseComponents/Row";
import ErrorMessage from "../../components/baseComponents/ErrorMessage";
import NucleusLoader from "../components/NucleusLoader";
import OverviewHeader from "../components/OverviewHeader";
import Button from "../../components/baseComponents/Button";
import useCompanyFromParams from "../utils/useCompanyFromParams";
import Tab from "../components/TabNucleus";
import TabSection from "../components/TabSection";
import IntegrationTab from "../components/CompanyIntegrationTab";
import { retrieveCompanyByUUID } from "../utils/companyUtils";
import {
  NucleusCompany,
  GenericObject,
} from "../../components/global/ModelInterfaces";
import { updateCompany } from "../../store/nucleusCompanies";
import companyOverViewTabs from "../../enums/companyOverviewTabs";
import CompanyInfoTab from "../components/CompanyOverviewSections/CompanyInfoTab";
import CompanyToolsTab from "../components/CompanyOverviewSections/CompanyToolsTab";
import CompanyBuildingsTab from "../components/CompanyOverviewSections/CompanyBuildingsTab";

const StyledLoading = styled(Div)`
  font-size: ${(props) => props.theme.font_size.headlineLG};
  font-weight: ${(props) => props.theme.font_weight.bolder};
`;

const CompanyOverview = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { companyUUID } = useParams<GenericObject>();
  const { loadingCompanies, company, errorLoading, errorMessage } =
    useCompanyFromParams();
  const [loadingCompany, setLoadingCompany] = useState(true);
  const [error, setError] = useState("");
  const [currentTab, setCurrentTab] = useState(companyOverViewTabs.INFO_TAB);

  const renderCurrentTab = () => {
    switch (currentTab) {
      case companyOverViewTabs.INFO_TAB:
      default:
        return <CompanyInfoTab company={company} />;
      case companyOverViewTabs.BUILDINGS_TAB:
        return <CompanyBuildingsTab />;
      case companyOverViewTabs.TOOLS_TAB:
        return <CompanyToolsTab company={company} />;
      case companyOverViewTabs.INTEGRATION_TAB:
        return <IntegrationTab />;
    }
  };

  useEffect(() => {
    if (!company?.company_settings) {
      const successCallback = (companyDetails: NucleusCompany) => {
        dispatch(updateCompany(companyDetails));
        setLoadingCompany(false);
      };
      const failureCallback = () => {
        setError(
          "There was an error in retrieving the details of this company, please try again later."
        );
        setLoadingCompany(false);
      };
      retrieveCompanyByUUID(companyUUID, successCallback, failureCallback);
    } else {
      setLoadingCompany(false);
    }
  }, [company, loadingCompanies]);

  if (loadingCompanies || loadingCompany) {
    return (
      <Row justifyContent="center">
        <StyledLoading
          width={{ default: 1 }}
          alignItems="center"
          justifyContent="center"
        >
          <Div width={{ default: 2 / 12 }}>
            <NucleusLoader loadingText="Loading Company Details" />
          </Div>
        </StyledLoading>
      </Row>
    );
  }

  if (!company || !company.company_settings || errorLoading) {
    return (
      <Div p={{ default: 5 }}>
        <ErrorMessage>{error || errorMessage}</ErrorMessage>
        <Button onClick={() => history.push("/nucleus/company-search")}>
          Back
        </Button>
      </Div>
    );
  }

  return (
    <Row justifyContent="center">
      <Div width={{ default: 11 / 12 }}>
        <Row>
          <Div width={{ lg: 12 / 12 }}>
            <OverviewHeader
              overviewHeader={company.name}
              overviewName={""}
              LeftSubHeader={`${company.street_address}, ${company.city}, ${company.state} ${company.zip_code}`}
              rightSubHeader={""}
              tabs={
                <TabSection>
                  <Div width={"fill"}>
                    <Tab
                      name="Info"
                      active={currentTab === companyOverViewTabs.INFO_TAB}
                      onClick={() => {
                        setCurrentTab(companyOverViewTabs.INFO_TAB);
                      }}
                    />
                  </Div>
                  <Div width={"fill"}>
                    <Tab
                      name="Buildings"
                      active={currentTab === companyOverViewTabs.BUILDINGS_TAB}
                      onClick={() => {
                        setCurrentTab(companyOverViewTabs.BUILDINGS_TAB);
                      }}
                    />
                  </Div>
                  <Div width={"fill"}>
                    <Tab
                      name="Tools"
                      active={currentTab === companyOverViewTabs.TOOLS_TAB}
                      onClick={() => {
                        setCurrentTab(companyOverViewTabs.TOOLS_TAB);
                      }}
                    />
                  </Div>
                  <Div width={"fill"}>
                    <Tab
                      name={company.integration_name || "Integration"}
                      active={
                        currentTab === companyOverViewTabs.INTEGRATION_TAB
                      }
                      onClick={() => {
                        setCurrentTab(companyOverViewTabs.INTEGRATION_TAB);
                      }}
                    />
                  </Div>
                </TabSection>
              }
            />
          </Div>
        </Row>
        {renderCurrentTab()}
      </Div>
    </Row>
  );
};

export default CompanyOverview;
