import { useState } from "react";
import styled from "styled-components";

import Div from "../../baseComponents/Div";
import Row from "../../baseComponents/Row";
import RentableCard from "../../baseComponents/RentableCard";
import ExtraPayment from "./ExtraPayment";
import PaymentProcessorRestricted from "../PaymentProcessorRestricted";
import { GenericObject } from "../../global/ModelInterfaces";
import { currencyFormatter, monthDayYearFormatter } from "../../global/utils";

interface Props {
  prepaymentInfo: GenericObject;
  personalInfo: GenericObject;
}

const StyledLabel = styled(Div)`
  font-weight: 600;
`;
const StyledDetailRow = styled(Row)`
  margin-bottom: 0.75rem;
`;

const PaymentPlanCard = ({ prepaymentInfo, personalInfo }: Props) => {
  const [depositRemainingBalance, setDepositRemainingBalance] = useState(
    prepaymentInfo.deposits.security_deposit?.payment_plan?.remaining_balance ||
      0
  );
  const onPaymentSuccess = (paymentAmount: number) => {
    setDepositRemainingBalance(depositRemainingBalance - paymentAmount);
  };

  return (
    <>
      <RentableCard title={"Your Monthly Payment Plan"}>
        <Row>
          <Div width={{ sm: 1, md: 1, lg: 1 / 2 }}>
            <Div width={{ default: 1 }}>
              <StyledDetailRow>
                <StyledLabel width={{ sm: 1, lg: 2 / 3 }}>
                  Security Deposit Amount:
                </StyledLabel>
                <Div width={{ lg: 1 / 3 }}>
                  {currencyFormatter.format(
                    prepaymentInfo.deposits.security_deposit?.amount || 0
                  )}
                </Div>
              </StyledDetailRow>
            </Div>
            <Div width={{ default: 1 }}>
              <StyledDetailRow>
                <StyledLabel width={{ sm: 1, lg: 2 / 3 }}>
                  Deposit Remaining Balance:
                </StyledLabel>
                <Div width={{ lg: 1 / 3 }}>
                  {currencyFormatter.format(depositRemainingBalance)}
                </Div>
              </StyledDetailRow>
            </Div>
            <Div width={{ default: 1 }}>
              <StyledDetailRow>
                <StyledLabel width={{ sm: 1, lg: 2 / 3 }}>
                  Lease Start Date:
                </StyledLabel>
                <Div width={{ lg: 1 / 3 }}>
                  {monthDayYearFormatter(prepaymentInfo.start_date)}
                </Div>
              </StyledDetailRow>
            </Div>
            <Div width={{ default: 1 }}>
              <StyledDetailRow>
                <StyledLabel width={{ sm: 1, lg: 2 / 3 }}>
                  Last Payment Made:
                </StyledLabel>
                <Div width={{ lg: 1 / 3 }}>
                  {monthDayYearFormatter(
                    prepaymentInfo.deposits.security_deposit?.payment_plan
                      ?.last_payment || 0
                  )}
                </Div>
              </StyledDetailRow>
            </Div>
          </Div>
          {personalInfo.payment_processor_restricted ? (
            <Div width={{ sm: 1, md: 1, lg: 6 / 12 }}>
              <PaymentProcessorRestricted />
            </Div>
          ) : (
            <ExtraPayment
              prepaymentInfo={prepaymentInfo}
              onSuccess={onPaymentSuccess}
            />
          )}
        </Row>
      </RentableCard>
    </>
  );
};

export default PaymentPlanCard;
