import CreateDepositsCard from "./CreateDeposits";
import CreateUnitsCard from "./CreateUnits";
import { NucleusCompany } from "../../../components/global/ModelInterfaces";
import Row from "../../../components/baseComponents/Row";

interface Props {
  company: NucleusCompany;
}

const CompanyToolsTab = ({ company }: Props) => {
  return (
    <>
      <Row mt={{ default: 3 }}>
        <CreateDepositsCard company={company} />
      </Row>
      <Row mt={{ default: 3 }}>
        <CreateUnitsCard companyUUID={company.uuid} />
      </Row>
    </>
  );
};

export default CompanyToolsTab;
