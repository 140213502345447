import { useRef, useState, ChangeEvent, ChangeEventHandler } from "react";
import { first } from "lodash";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { RootState } from "../..";
import UploadIcon from "../../images/UploadIcon";
import styled from "styled-components";
import Div from "./Div";
import Row from "./Row";
import XIcon from "../../images/XIcon";
import Button from "./Button";
import BeatLoader from "react-spinners/BeatLoader";
import { Prepayment } from "../global/ModelInterfaces";
import {
  FILE_SIZE_LIMIT_MBS,
  checkAllowedFileUpload,
  checkFileSize,
  renderFileTypeError,
  submitDocumentUpload,
} from "../../utils/documentUtils";
import { setDocuments } from "../../store/tenantInfo";
import { updatePrepayment } from "../../store/prepayments";
const StyledUploadLink = styled(Row)`
  text-decoration: none;
  cursor: pointer;
  margin: 0.5rem 0rem;
`;

const StyledHiddenInput = styled.div`
  display: none;
`;

interface Props {
  onUpload?: (e: any) => void;
  onRemove?: () => void;
  onSubmit?: () => void;
  prepayment?: Prepayment;
  confirmUpload?: boolean;
  handleW9?: boolean;
  defaultText?: string;
}
const FileUpload = ({
  onUpload,
  onRemove,
  onSubmit,
  prepayment,
  confirmUpload,
  handleW9,
  defaultText,
}: Props) => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector((state: RootState) => state.auth.user);
  const [file, setFile] = useState<File | null>(null);
  const [isW9, setIsW9] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState("");
  const fileInputRef = useRef<any>();

  const onFileSelect: ChangeEventHandler<HTMLInputElement> = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.files) {
      setError("");
      const newFile = first(e.target.files);
      if (
        newFile &&
        checkAllowedFileUpload(newFile) &&
        checkFileSize(newFile)
      ) {
        if (onUpload) onUpload(e);
        setFile(newFile);
      } else if (!checkAllowedFileUpload(newFile)) {
        setError(renderFileTypeError());
      } else if (!checkFileSize(newFile)) {
        setError(
          `Oops! File size limit exceeded. Please make sure your file is less than ${FILE_SIZE_LIMIT_MBS} MB!`
        );
      }
    }
  };

  const handleUploadDocument = () => {
    if (!file) {
      setError("Oops something went wrong, please try again");
      return;
    }
    setUploading(true);
    submitDocumentUpload(file, prepayment?.uuid, currentUser.id, isW9)
      .then((updatedPrepayment) => {
        if (currentUser.role === "tenant") {
          dispatch(
            setDocuments(
              updatedPrepayment.document_list,
              updatedPrepayment.uuid
            )
          );
        } else {
          dispatch(updatePrepayment(updatedPrepayment));
        }
        if (onSubmit) {
          onSubmit();
        }
        reset();
      })
      .catch((e: any) => {
        setError(e.message);
      })
      .finally(() => {
        setUploading(false);
      });
  };

  const toggleIsW9 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsW9(e.target.checked);
  };

  const reset = () => {
    setFile(null);
    setIsW9(false);
  };

  const getDefaultText = () => {
    return defaultText || "Upload Media or Receipt";
  };

  return (
    <Div width={{ default: 1 }}>
      <Row justifyContent="center">{error}</Row>
      {uploading ? (
        <BeatLoader />
      ) : (
        <Row alignItems="center">
          <Div>
            <StyledUploadLink>
              <a
                onClick={() => {
                  fileInputRef.current.click();
                }}
              >
                <UploadIcon /> {file?.name || getDefaultText()}
              </a>
              <StyledHiddenInput>
                <input
                  type="file"
                  accept=".png, .jpg, .jpeg, .pdf, .csv"
                  ref={fileInputRef}
                  onChange={onFileSelect}
                />
              </StyledHiddenInput>
            </StyledUploadLink>
          </Div>
          {file && handleW9 && (
            <Div>
              <Row alignItems="center">
                <span>This is a W9</span>
                <input type="checkbox" onChange={toggleIsW9}></input>
              </Row>
            </Div>
          )}
          {file && confirmUpload && (
            <Div width={{ default: 2 / 3, md: 1 / 4, lg: 1 / 4 }}>
              <Button
                type="primary"
                text="Confirm Upload"
                onClick={() => handleUploadDocument()}
              />
            </Div>
          )}
          {file && (
            <Div
              onClick={() => {
                if (onRemove) onRemove();
                reset();
              }}
            >
              <XIcon />
            </Div>
          )}
        </Row>
      )}
    </Div>
  );
};

export default FileUpload;
