import { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams, Redirect, useHistory } from "react-router-dom";
import { useAppDispatch } from "../../store/hooks";
import depositTypes from "../../enums/depositTypes";
import { every, some } from "lodash";
import { DateTime } from "luxon";
import Container from "../baseComponents/Container";
import Row from "../baseComponents/Row";
import Div from "../baseComponents/Div";
import ContactTenant from "./ContactTenant";
import MetricsOverview from "../baseComponents/MetricsOverview";
import BackButton from "../baseComponents/BackButton";
import PageLoader from "../PageLoader";
import SadCoin from "../../images/SadCoin";
import FileUpload from "../baseComponents/FileUpload";
import DocumentIcon from "../../images/DocumentIcon";
import XButton from "../baseComponents/XButton";
import Menu from "../baseComponents/Menu";
import ErrorMessage from "../baseComponents/ErrorMessage";
import UserInitialsAvatar from "../baseComponents/UserInitialsAvatar";
import ConfirmationModal from "../baseComponents/ConfirmationModal";
import {
  GenericObject,
  Metric,
  newMetric,
  MenuItem,
  Document,
  AdditionalFundsRequest,
  Fee,
} from "../global/ModelInterfaces";
import { deletePrepayment, updatePrepayment } from "../../store/prepayments";
import { removeInvite } from "../../store/invite";
import {
  submitDepositDelete,
  getPrepaymentDetails,
  hasPendingAdditionalFundsRequests,
  deleteAdditionalFundsRequest,
} from "../../utils/depositUtils";
import {
  formatDateValueAsLocale,
  currencyFormatter,
  monthDayYearFormatter,
} from "../global/utils";
import {
  handleDownloadW9Pdf,
  handleDocumentDownload,
  downloadReceipt,
  downloadFeesReceipt,
  downloadDeductionsReceipt,
  submitDocumentDelete,
} from "../../utils/documentUtils";
import { getStreetAddress } from "../../utils/globalUtils";
import prepaymentStatuses from "../../enums/prepaymentStatuses";
import useGetPrepayment from "../../utils/useGetPrepayment";
import documentTypes from "../../enums/documentTypes";
import { COLORS } from "../global/colors";

const StyledName = styled(Div)`
  line-height: ${(props) => props.theme.font_size.headlineLG};
  font-size: ${(props) => props.theme.font_size.headlineLG};
  font-weight: ${(props) => props.theme.font_weight.semibold};
  color: ${(props) => props.theme.colors.navy};
`;

const StyledDetail = styled.div`
  font-size: ${(props) => props.theme.font_size.bodyMD};
`;

const StyledTitle = styled(Div)`
  font-weight: ${(props) => props.theme.font_weight.semibold};
  font-size: ${(props) => props.theme.font_size.headlineMD};
  color: ${(props) => props.theme.colors.navy};
`;

const StyledItem = styled(Div)`
  font-size: ${(props) => props.theme.font_size.headlineXS};
  color: ${(props) => props.theme.colors.grey80};
`;

const StyledDownload = styled(Div)`
  font-size: ${(props) => props.theme.font_size.bodyLG};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const TenantView = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { prepaymentUUID } = useParams<GenericObject>();
  const { selectedPrepayment, loadingPrepayments } = useGetPrepayment();
  const [deleting, setDeleting] = useState(false);
  const [deleteFailPrepayment, setDeleteFailPrepayment] = useState(false);
  const [deleteFailDocument, setDeleteFailDocument] = useState(false);
  const [confirmRequestDelete, setConfirmRequestDelete] = useState(false);
  const [requestToDelete, setRequestToDelete] = useState<GenericObject | null>(
    null
  );
  const [error, setError] = useState("");

  useEffect(() => {
    if (loadingPrepayments) {
      return;
    }
    if (selectedPrepayment) {
      if (missingDetails()) {
        const successCallback = (details: GenericObject) => {
          const updatedPrepayment = {
            ...selectedPrepayment,
            ...details,
          };
          dispatch(updatePrepayment(updatedPrepayment));
        };
        const errorCallback = (error: GenericObject) => {
          setError(error.data);
        };
        getPrepaymentDetails(
          selectedPrepayment.uuid,
          successCallback,
          errorCallback
        );
      }
    }
  }, [selectedPrepayment]);

  const handleDeletePrepayment = (uuid: string) => {
    setDeleting(true);
    submitDepositDelete(selectedPrepayment?.uuid)
      .then(() => {
        dispatch(deletePrepayment(selectedPrepayment?.uuid));
        dispatch(removeInvite(selectedPrepayment?.uuid));
        history.push("/dashboard");
      })
      .catch((e) => {
        setDeleteFailPrepayment(true);
      })
      .finally(() => {
        setDeleting(false);
      });
  };

  const handleDeleteRequest = () => {
    if (!requestToDelete) {
      setError(
        "There was an error trying to delete this request, please refresh and try again."
      );
    } else {
      const successCallback = (updatedPrepayment: GenericObject) => {
        setConfirmRequestDelete(false);
        dispatch(updatePrepayment(updatedPrepayment));
      };
      const errorCallback = (errorMessage: string) => {
        setConfirmRequestDelete(false);
        setError(errorMessage);
      };

      deleteAdditionalFundsRequest(
        requestToDelete.uuid,
        successCallback,
        errorCallback
      );
    }
  };

  const renderConfirmationMessage = () => {
    if (requestToDelete) {
      return `Please confirm you would like to delete the following additional funds requested on: ${`${DateTime.fromISO(
        requestToDelete.requested_at
      ).toFormat("MMMM dd, yyyy")}`} for ${currencyFormatter.format(
        requestToDelete.amount
      )}. `;
    } else {
      return "";
    }
  };

  const allDepositsPending = every(selectedPrepayment?.deposits, [
    "status_id",
    prepaymentStatuses.PENDING,
  ]);

  const someDepositsPending = some(selectedPrepayment?.deposits, [
    "status_id",
    prepaymentStatuses.PENDING,
  ]);

  const renderMenuItems = () => {
    const menuItems: MenuItem[] = [];
    if (allDepositsPending) {
      menuItems.push({
        label: "Delete Deposit",
        function: () => {
          handleDeletePrepayment(prepaymentUUID);
        },
      });
    }
    if (
      someDepositsPending ||
      hasPendingAdditionalFundsRequests(selectedPrepayment?.deposits)
    ) {
      menuItems.push({
        label: "Fund Deposit",
        function: () => {
          history.push(`/dashboard/fund-deposit/${selectedPrepayment?.uuid}`);
        },
      });
    }
    if (selectedPrepayment?.is_disbursable) {
      menuItems.push({
        label: "Close Deposit",
        function: () => {
          history.push(`/dashboard/close-deposit/${selectedPrepayment?.uuid}`);
        },
      });
    }
    if (selectedPrepayment?.has_disbursable_rent) {
      menuItems.push({
        label: "Release Last Month's Rent",
        function: () => {
          history.push(`/dashboard/release-rent/${selectedPrepayment?.uuid}`);
        },
      });
    }
    menuItems.push({
      label: "Update lease dates",
      function: () => {
        history.push(`/dashboard/update-dates/${selectedPrepayment?.uuid}`);
      },
    });
    if (canRequestAdditionalFunds()) {
      menuItems.push({
        label: "Request Additional Funds",
        function: () => {
          history.push(
            `/dashboard/request-additional-funds/${selectedPrepayment?.uuid}`
          );
        },
      });
    }
    if (selectedPrepayment?.status_id === prepaymentStatuses.ACTIVE) {
      menuItems.push({
        label: "Create Partial Refund",
        function: () => {
          history.push(`/dashboard/create-refund/${selectedPrepayment?.uuid}`);
        },
      });
    }
    menuItems.push({
      label: "Transfer Lease to Another Unit",
      function: () => {
        history.push(`/dashboard/update-unit/${selectedPrepayment?.uuid}`);
      },
    });
    return menuItems;
  };

  const canRequestAdditionalFunds = () => {
    return (
      selectedPrepayment?.status_id &&
      selectedPrepayment.status_id === prepaymentStatuses.ACTIVE &&
      !hasPendingAdditionalFundsRequests(selectedPrepayment.deposits)
    );
  };

  const snakeCaseConvert = (key: string) => {
    let temp = key.replaceAll("_", " ");
    return temp.replace(
      /\w\S*/g,
      (text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
    );
  };

  const missingDetails = () => {
    if (selectedPrepayment) {
      return !(selectedPrepayment.document_list && selectedPrepayment.deposits);
    }
    return true;
  };

  const handleDeleteDocument = (document: Document) => {
    submitDocumentDelete(document.uuid, selectedPrepayment?.uuid)
      .then((updatedPrepayment) => {
        dispatch(updatePrepayment(updatedPrepayment));
      })
      .catch(() => {
        setDeleteFailDocument(true);
      });
  };

  const renderFees = (fee: Fee) => {
    if (loadingPrepayments) {
      return (
        <Div>
          <PageLoader />
        </Div>
      );
    }
    return (
      <>
        <StyledItem
          mb={{ default: 2 }}
          width={{ sm: 8 / 12, md: 6 / 12, lg: 6 / 12 }}
        >
          <Row>{fee.type}</Row>
        </StyledItem>
        <StyledItem
          mb={{ default: 2 }}
          width={{ sm: 8 / 12, md: 6 / 12, lg: 6 / 12 }}
        >
          <Row>{currencyFormatter.format(fee.amount)}</Row>
        </StyledItem>
        {fee.description && (
          <>
            <StyledItem
              mb={{ default: 2 }}
              width={{ sm: 4 / 12, md: 6 / 12, lg: 6 / 12 }}
            >
              Description:
            </StyledItem>
            <StyledItem
              mb={{ default: 2 }}
              width={{ sm: 8 / 12, md: 6 / 12, lg: 6 / 12 }}
            >
              <Row>{fee.description}</Row>
            </StyledItem>
          </>
        )}
      </>
    );
  };
  const renderTenantDeposits = (type: string, deposit: any) => {
    const w9 = selectedPrepayment?.document_list?.find(
      (document: Document) => document.type === documentTypes.W_9
    );
    if (loadingPrepayments) {
      return (
        <Div>
          <PageLoader />
        </Div>
      );
    }
    return (
      <Div width={{ default: 1 }}>
        <Row>
          <StyledTitle
            width={{ default: 1 }}
            mt={{ default: 4 }}
            mb={{ default: 3 }}
          >
            {snakeCaseConvert(type)}
          </StyledTitle>
        </Row>
        <Div width={{ default: 1 }}>
          <Row>
            <StyledItem
              mb={{ default: 2 }}
              width={{ sm: 4 / 12, md: 6 / 12, lg: 6 / 12 }}
            >
              Status:
            </StyledItem>
            <StyledItem
              mb={{ default: 2 }}
              width={{ sm: 8 / 12, md: 6 / 12, lg: 6 / 12 }}
            >
              <Row>{deposit.status}</Row>
            </StyledItem>
            <StyledItem
              mb={{ default: 2 }}
              width={{ sm: 4 / 12, md: 6 / 12, lg: 6 / 12 }}
            >
              Amount:
            </StyledItem>
            <StyledItem
              mb={{ default: 2 }}
              width={{ sm: 8 / 12, md: 6 / 12, lg: 6 / 12 }}
            >
              <Row>{currencyFormatter.format(deposit.amount)}</Row>
            </StyledItem>
            {deposit.additional_funds_requests.length > 0 && (
              <>
                <StyledItem mb={{ default: 2 }} width={{ sm: 1 }}>
                  Additional Funds Requests:
                </StyledItem>
                {deposit.additional_funds_requests.map(
                  (request: AdditionalFundsRequest) => {
                    return (
                      <StyledItem
                        mb={{ default: 2 }}
                        ml={{ default: 4 }}
                        width={{ sm: 1 }}
                      >
                        <Row alignItems="center">
                          <span>
                            Requested on{" "}
                            {`${DateTime.fromISO(request.requested_at).toFormat(
                              "MMMM dd, yyyy"
                            )}`}
                          </span>
                          {request.status_id == prepaymentStatuses.PENDING && (
                            <XButton
                              height="24"
                              width="24"
                              color={COLORS.danger}
                              onClick={() => {
                                setRequestToDelete(request);
                                setConfirmRequestDelete(true);
                              }}
                            />
                          )}
                        </Row>
                        <Row>
                          Amount: {currencyFormatter.format(request.amount)}
                        </Row>
                        <Row>
                          Status:{" "}
                          {request.status_id == prepaymentStatuses.CLOSED
                            ? "Funded"
                            : request.status}
                        </Row>
                      </StyledItem>
                    );
                  }
                )}
              </>
            )}
            {(deposit.total_accrued_interest ||
              deposit.total_accrued_interest === 0) && (
              <>
                <StyledItem
                  mb={{ default: 2 }}
                  width={{ sm: 4 / 12, md: 6 / 12, lg: 6 / 12 }}
                >
                  Interest Accrual Since Last Disbursement:
                </StyledItem>
                <StyledItem
                  mb={{ default: 2 }}
                  width={{ sm: 8 / 12, md: 6 / 12, lg: 6 / 12 }}
                >
                  <Row>
                    {currencyFormatter.format(deposit.total_accrued_interest)}
                  </Row>
                </StyledItem>
              </>
            )}
            {deposit.latest_interest_disbursal_date && (
              <>
                <StyledItem
                  mb={{ default: 2 }}
                  width={{ sm: 4 / 12, md: 6 / 12, lg: 6 / 12 }}
                >
                  Interest Disbursement:
                </StyledItem>
                <StyledItem
                  mb={{ default: 2 }}
                  width={{ sm: 8 / 12, md: 6 / 12, lg: 6 / 12 }}
                >
                  <Row>
                    {currencyFormatter.format(
                      deposit.latest_interest_disbursal_amount
                    )}{" "}
                    - on{" "}
                    {`${DateTime.fromISO(
                      deposit.latest_interest_disbursal_date
                    ).toFormat("MMMM dd, yyyy")}`}
                  </Row>
                </StyledItem>
              </>
            )}
          </Row>
        </Div>
        <Div width={{ default: 1 }}>
          <Row flexWrap="nowrap">
            <StyledItem
              mb={{ default: 2 }}
              width={{ sm: 4 / 12, md: 6 / 12, lg: 6 / 12 }}
            >
              Files:
            </StyledItem>
            <Div width={{ sm: 8 / 12, md: 6 / 12, lg: 6 / 12 }}>
              {w9 && deposit.type === depositTypes.SECURITY_DEPOSIT && (
                <StyledDownload
                  onClick={() => {
                    if (w9.name) {
                      handleDocumentDownload(w9.uuid, w9.name);
                    } else {
                      handleDownloadW9Pdf(selectedPrepayment?.uuid);
                    }
                  }}
                >
                  <Row>
                    <Div display={{ sm: "none", md: "flex", lg: "flex" }}>
                      <DocumentIcon />
                    </Div>
                    <span>W9 Form</span>
                  </Row>
                </StyledDownload>
              )}
              {deposit.type === depositTypes.SECURITY_DEPOSIT &&
                deposit.status_id >= prepaymentStatuses.PROCESSING && (
                  <StyledDownload
                    onClick={() => {
                      downloadReceipt(deposit.uuid, "security_deposit");
                    }}
                  >
                    <Row flexWrap="nowrap">
                      <Div display={{ sm: "none", md: "flex", lg: "flex" }}>
                        <DocumentIcon />
                      </Div>
                      <span>Security Deposit Receipt</span>
                    </Row>
                  </StyledDownload>
                )}
              {[
                prepaymentStatuses.DISBURSING,
                prepaymentStatuses.CLOSED,
              ].includes(deposit.status_id) &&
                deposit.type === depositTypes.SECURITY_DEPOSIT && (
                  <StyledDownload
                    onClick={() => {
                      downloadDeductionsReceipt(deposit.uuid);
                    }}
                  >
                    <Row flexWrap="nowrap">
                      <Div display={{ sm: "none", md: "flex", lg: "flex" }}>
                        <DocumentIcon />
                      </Div>
                      <span>Itemized Deduction List Statement</span>
                    </Row>
                  </StyledDownload>
                )}
              {deposit.type === depositTypes.LAST_MONTHS_RENT &&
                deposit.status_id >= prepaymentStatuses.PROCESSING && (
                  <StyledDownload
                    onClick={() => {
                      downloadReceipt(deposit.uuid, "last_months_rent");
                    }}
                  >
                    <Row flexWrap="nowrap">
                      <Div display={{ sm: "none", md: "flex", lg: "flex" }}>
                        <DocumentIcon />
                      </Div>
                      <span>Last Month's Rent Receipt</span>
                    </Row>
                  </StyledDownload>
                )}
            </Div>
          </Row>
        </Div>
      </Div>
    );
  };

  if (error) {
    return (
      <Div>
        <ErrorMessage>{error}</ErrorMessage>
      </Div>
    );
  }

  if (loadingPrepayments || deleting || missingDetails()) {
    return (
      <Div>
        <PageLoader />
      </Div>
    );
  }

  if (!selectedPrepayment) {
    return <Redirect to={"/forbidden"} />;
  } else if (deleteFailPrepayment || deleteFailDocument) {
    return (
      <Row justifyContent="center">
        <Div alignItems="center" width={{ default: 4 / 12 }}>
          <SadCoin />
          There was a problem deleting this {deleteFailPrepayment && "deposit"}
          {deleteFailDocument && "document"}, please refresh the page and try
          again, or contact support@rentable.com if this issue persists.
        </Div>
      </Row>
    );
  } else {
    const tenantMetrics: Metric[] = [
      newMetric("Status", selectedPrepayment.status),
      newMetric(
        "Amount",
        `${currencyFormatter.format(selectedPrepayment?.amount)}`
      ),
      newMetric(
        "Start Date",
        formatDateValueAsLocale(selectedPrepayment.start_date)
      ),
    ];
    if (selectedPrepayment.end_date) {
      tenantMetrics.push(
        newMetric(
          "End Date",
          formatDateValueAsLocale(selectedPrepayment.end_date)
        )
      );
    }
    return (
      <>
        <Container>
          <Div display={{ sm: "none", md: "flex", lg: "flex" }}>
            <BackButton />
          </Div>
          <Row justifyContent="center">
            <Div width={{ sm: 1, md: 5 / 8, lg: 5 / 8 }}>
              <Row mb={{ default: 4 }} alignItems="space-between">
                <Div width={{ sm: 1, md: 5 / 8, lg: 4 / 8 }}>
                  <Row flexWrap="none">
                    <Div
                      mt={{ default: 1 }}
                      display={{ sm: "none", md: "flex", lg: "flex" }}
                    >
                      <UserInitialsAvatar
                        name={selectedPrepayment?.tenant_name}
                      />
                    </Div>
                    <Div>
                      <StyledName mb={{ default: 2 }}>
                        {selectedPrepayment?.tenant_name}
                      </StyledName>
                      <StyledDetail>
                        {selectedPrepayment?.tenant_email}
                      </StyledDetail>
                      <StyledDetail>
                        {getStreetAddress(
                          selectedPrepayment.address_1,
                          selectedPrepayment.address_2
                        )}
                      </StyledDetail>
                    </Div>
                  </Row>
                </Div>
                <Div width={{ sm: 1, md: 1, lg: 4 / 8 }}>
                  <Row justifyContent="flex-end">
                    <Div width={{ sm: 6 / 8, md: 7 / 8, lg: 6 / 12 }}>
                      <ContactTenant />
                    </Div>
                    <Div width={{ sm: 2 / 8, md: 1 / 8, lg: 1 / 8 }}>
                      <Menu menuItems={renderMenuItems()} style="ellipsis" />
                    </Div>
                  </Row>
                </Div>
              </Row>
              <Row>
                <Div width={{ sm: 1, md: 1, lg: 1 }}>
                  <MetricsOverview
                    metrics={tenantMetrics}
                    prepayment={selectedPrepayment}
                  />
                </Div>
              </Row>
              {Object.keys(selectedPrepayment.deposits).map((key: string) => {
                return (
                  <Row>
                    {renderTenantDeposits(
                      key,
                      selectedPrepayment?.deposits[key]
                    )}
                  </Row>
                );
              })}
              {selectedPrepayment.fees.length > 0 && (
                <Row>
                  <Div width={{ default: 1 }}>
                    <Row alignItems="center">
                      <StyledTitle mt={{ default: 4 }} mb={{ default: 3 }}>
                        Fees
                      </StyledTitle>
                    </Row>
                    {selectedPrepayment.fees.map((fee: Fee) => {
                      return <Row>{renderFees(fee)}</Row>;
                    })}
                    <Div width={{ default: 1 }}>
                      <Row flexWrap="nowrap">
                        <StyledItem
                          mb={{ default: 2 }}
                          width={{ sm: 4 / 12, md: 6 / 12, lg: 6 / 12 }}
                        >
                          Files:
                        </StyledItem>
                        {selectedPrepayment.fees.every(
                          (fee: Fee) =>
                            fee.status !== prepaymentStatuses.PENDING
                        ) && (
                          <Div width={{ sm: 8 / 12, md: 6 / 12, lg: 6 / 12 }}>
                            <StyledDownload
                              onClick={() => {
                                downloadFeesReceipt(selectedPrepayment?.uuid);
                              }}
                            >
                              <Row>
                                <Div
                                  display={{
                                    sm: "none",
                                    md: "flex",
                                    lg: "flex",
                                  }}
                                >
                                  <DocumentIcon />
                                </Div>
                                <span>Fees Receipt</span>
                              </Row>
                            </StyledDownload>
                          </Div>
                        )}
                      </Row>
                    </Div>
                  </Div>
                </Row>
              )}
              {selectedPrepayment.insurance_enrollment && (
                <Row>
                  <Div width={{ default: 1 }}>
                    <Row alignItems="center">
                      <StyledTitle mt={{ default: 4 }} mb={{ default: 3 }}>
                        Renters Insurance
                      </StyledTitle>
                    </Row>
                    <Row>
                      <StyledItem
                        mb={{ default: 2 }}
                        width={{ default: 12 / 12, lg: 8 / 12 }}
                      >
                        {selectedPrepayment?.tenant_name} has enrolled in
                        renters insurance. If they uploaded evidence of
                        insurance, the document can be found in the Uploaded
                        Documents section below.
                      </StyledItem>
                    </Row>

                    <Row>
                      <StyledItem
                        mb={{ default: 2 }}
                        width={{ default: 7 / 12, lg: 6 / 12 }}
                      >
                        Enrollment Type:
                      </StyledItem>
                      <StyledItem
                        mb={{ default: 2 }}
                        width={{ default: 5 / 12, lg: 6 / 12 }}
                      >
                        <Row>
                          {
                            selectedPrepayment.insurance_enrollment
                              .enrollment_type
                          }
                        </Row>
                      </StyledItem>
                    </Row>
                    <Row>
                      <StyledItem
                        mb={{ default: 2 }}
                        width={{ default: 7 / 12, lg: 6 / 12 }}
                      >
                        Enrollment Date:
                      </StyledItem>
                      <StyledItem
                        mb={{ default: 2 }}
                        width={{ default: 5 / 12, lg: 6 / 12 }}
                      >
                        <Row>
                          {monthDayYearFormatter(
                            selectedPrepayment.insurance_enrollment.enrolled_at
                          )}
                        </Row>
                      </StyledItem>
                    </Row>
                  </Div>
                </Row>
              )}
              <Row>
                <Div width={{ default: 1 }}>
                  <Row alignItems="center">
                    <StyledTitle mt={{ default: 4 }} mb={{ default: 3 }}>
                      Uploaded Documents
                    </StyledTitle>
                  </Row>
                  <FileUpload
                    confirmUpload={true}
                    handleW9={true}
                    prepayment={selectedPrepayment}
                  />
                  {selectedPrepayment.document_list.map(
                    (document: Document) => {
                      if (document.type !== documentTypes.W_9)
                        return (
                          <Row alignItems="center">
                            <StyledDownload
                              onClick={() => {
                                handleDocumentDownload(
                                  document.uuid,
                                  document.name
                                );
                              }}
                            >
                              <Row>
                                <Div>
                                  <DocumentIcon />
                                </Div>
                                {document.name}
                              </Row>
                            </StyledDownload>
                            <XButton
                              onClick={() => handleDeleteDocument(document)}
                            />
                          </Row>
                        );
                    }
                  )}
                </Div>
              </Row>
            </Div>
          </Row>
        </Container>
        {requestToDelete && (
          <ConfirmationModal
            confirmationOpen={confirmRequestDelete}
            onClose={() => setConfirmRequestDelete(false)}
            buttonText="Delete Request"
            onConfirm={handleDeleteRequest}
            message={renderConfirmationMessage()}
          />
        )}
      </>
    );
  }
};

export default TenantView;
