import { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import styled from "styled-components";
import { currencyFormatter } from "../../global/utils";
import Button from "../../baseComponents/Button";
import Label from "../../baseComponents/Label";
import Row from "../../baseComponents/Row";
import Div from "../../baseComponents/Div";
import BackButton from "../../baseComponents/BackButton";
import PageLoader from "../../PageLoader";
import Input from "../../baseComponents/Input";
import ErrorMessage from "../../baseComponents/ErrorMessage";
import { GenericObject } from "../../global/ModelInterfaces";
import { cssBreakpoints } from "../../global/theme";
import { disburseViaCheck } from "../../../utils/disbursementUtils";
import useGetCurrentPrepayment from "../../../utils/useGetCurrentPrepayment";
const StyledContainer = styled(Row)`
  margin-top: 1rem;
`;

const StyledTitle = styled(Row)`
  font-size: ${(props) => props.theme.font_size.headlineSM};
  font-weight: ${(props) => props.theme.font_weight.semibold};
  ${cssBreakpoints("margin-top", [{ sm: "2rem" }, { md: "0" }])}
`;

const StyledRequestsContainer = styled(Row)`
  margin-top: 1rem;
`;

const StyledLineItem = styled(Row)`
  margin-top: 0.5rem;
  ${cssBreakpoints("white-space", [{ sm: "normal" }, { md: "nowrap" }])}
`;

const StyledTotal = styled(Row)`
  margin-top: 1rem;
`;

const StyledInstructions = styled(Row)`
  margin: 1.5rem 0;
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;

const StyledButtonRow = styled(Row)`
  margin: 1rem 0;
`;

const CheckDisbursementView = () => {
  const { prepaymentUUID } = useParams<GenericObject>();
  const { loadingTenantInfo, prepayment, offboarding } =
    useGetCurrentPrepayment(prepaymentUUID);
  const prepaymentInfo = prepayment;
  const [error, setError] = useState("");
  const [name, setName] = useState(prepaymentInfo.tenant_name);
  const [streetAddress, setStreetAddress] = useState("");
  const [unitAddress, setUnitAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [addressErrors, setAddressErrors] = useState<GenericObject>({});
  const [fetchingCheckoutSession, setFetchingCheckoutSession] = useState(false);

  const validateForm = () => {
    let tmpErrors = addressErrors;
    if (!name) {
      tmpErrors = { ...tmpErrors, name: true };
    }
    if (!streetAddress) {
      tmpErrors = { ...tmpErrors, streetAddress: true };
    }
    if (!city) {
      tmpErrors = { ...tmpErrors, city: true };
    }
    if (!state) {
      tmpErrors = { ...tmpErrors, state: true };
    }
    if (!zipcode) {
      tmpErrors = { ...tmpErrors, zipcode: true };
    }
    setAddressErrors(tmpErrors);
    setError(
      "Please enter all the required fields before continuing to payment."
    );
    return !Object.keys(tmpErrors).some((key: string) => !!tmpErrors[key]);
  };

  const clearError = (key: string) => {
    if (key === "all") {
      setAddressErrors({});
    } else {
      let tmpErrors = addressErrors;
      tmpErrors[key] = false;
      setAddressErrors(tmpErrors);
    }
  };

  const handlePayment = async () => {
    if (validateForm()) {
      setFetchingCheckoutSession(true);

      const failureCallback = (message: string) => {
        setFetchingCheckoutSession(false);
        setError(message);
      };

      await disburseViaCheck(
        name,
        streetAddress,
        unitAddress,
        city,
        state,
        zipcode,
        failureCallback,
        prepaymentUUID
      );
    }
  };

  useEffect(() => {
    setName(prepaymentInfo.tenant_name);
  }, [prepaymentInfo]);

  if (loadingTenantInfo || fetchingCheckoutSession) {
    return <PageLoader />;
  }

  if (!offboarding && !loadingTenantInfo) {
    return <Redirect to={`/dashboard`} />;
  }

  return (
    <Div alignItems="center">
      <Div width={{ default: 1, lg: 6 / 12 }}>
        <Row>
          <BackButton text="Go Back" returnUrl="/tenant/offboarding" />
        </Row>
        <StyledTitle justifyContent="center" alignItems="flex-start">
          Receive Your Funds Via Mail
        </StyledTitle>

        <StyledRequestsContainer>
          {parseFloat(offboarding.returned_deposit_amount) > 0 && (
            <LineItem
              title={"Security Deposit Amount"}
              amount={offboarding.returned_deposit_amount}
            />
          )}
          {parseFloat(offboarding.security_deposit_interest) > 0 && (
            <LineItem
              title={"Security Deposit Interest"}
              amount={offboarding.security_deposit_interest}
            />
          )}
          {parseFloat(offboarding.last_months_rent_amount) > 0 && (
            <LineItem
              title={"Last Month's Rent Amount"}
              amount={offboarding.returned_last_months_rent_amount}
            />
          )}
          {parseFloat(offboarding.last_months_rent_interest) > 0 && (
            <LineItem
              title={"Last Month's Rent Interest"}
              amount={offboarding.last_months_rent_interest}
            />
          )}
          <StyledTotal alignItems="end">
            <Label width={{ default: 8 / 12, lg: 6 / 12 }}>
              Total amount to be returned:
            </Label>
            <Div>
              {currencyFormatter.format(offboarding.total_amount_to_disburse)}
            </Div>
          </StyledTotal>
          <StyledTotal alignItems="end">
            <Label width={{ default: 8 / 12, lg: 6 / 12 }}>Fee Amount:</Label>
            <Div>
              {currencyFormatter.format(offboarding.lob_check_service_fee)}
            </Div>
          </StyledTotal>
        </StyledRequestsContainer>
        <StyledInstructions justifyContent="center">
          <Row justifyContent="center">
            <span>
              Please enter a mailing address where you want your check to go to.
            </span>
          </Row>
        </StyledInstructions>
        {error && (
          <Div>
            <Row justifyContent="center">
              <ErrorMessage>{error}</ErrorMessage>
            </Row>
          </Div>
        )}
        <StyledContainer>
          <Row>
            <Div width={{ sm: 1, md: 1, lg: 1 }}>
              <Input
                label="Name"
                value={name}
                backgroundColor={"light"}
                onChange={(e) => {
                  clearError("name");
                  setName(e.target.value);
                }}
                type="light"
                error={addressErrors.name}
              />
            </Div>
          </Row>
          <Row>
            <Div width={{ sm: 1, md: 1, lg: 9 / 12 }}>
              <Input
                label="Street Address"
                value={streetAddress}
                backgroundColor={"light"}
                onChange={(e) => {
                  clearError("streetAddress");
                  setStreetAddress(e.target.value);
                }}
                type="light"
                error={addressErrors.streetAddress}
              />
            </Div>
            <Div width={{ sm: 1, md: 1, lg: 3 / 12 }}>
              <Input
                label="Unit Address"
                value={unitAddress}
                backgroundColor={"light"}
                onChange={(e) => {
                  setUnitAddress(e.target.value);
                }}
                type="light"
              />
            </Div>
          </Row>
          <Row>
            <Div width={{ sm: 1, md: 1, lg: 1 / 2 }}>
              <Input
                label="City"
                value={city}
                backgroundColor={"light"}
                onChange={(e) => {
                  clearError("city");
                  setCity(e.target.value);
                }}
                type="light"
                error={addressErrors.city}
              />
            </Div>
            <Div width={{ sm: 1, md: 1, lg: 1 / 2 }}>
              <Input
                label="State"
                value={state}
                backgroundColor={"light"}
                onChange={(e) => {
                  clearError("state");
                  setState(e.target.value);
                }}
                type="light"
                error={addressErrors.state}
              />
            </Div>
          </Row>
          <Row>
            <Div width={{ sm: 1, md: 1, lg: 1 }}>
              <Input
                label="Zip Code"
                value={zipcode}
                backgroundColor={"light"}
                onChange={(e) => {
                  clearError("zipcode");
                  setZipcode(e.target.value);
                }}
                type="light"
                error={addressErrors.zipcode}
              />
            </Div>
          </Row>
          <StyledButtonRow justifyContent="center">
            <Div>
              <Button text="Continue to Payment" onClick={handlePayment} />
            </Div>
          </StyledButtonRow>
        </StyledContainer>
      </Div>
    </Div>
  );
};

interface LineItemProps {
  title: string;
  amount: string;
}

const LineItem = ({ title, amount }: LineItemProps) => {
  return (
    <StyledLineItem alignItems="end">
      <Label width={{ default: 8 / 12, lg: 6 / 12 }}>{title}:</Label>
      <Div>{currencyFormatter.format(parseFloat(amount))}</Div>
    </StyledLineItem>
  );
};

export default CheckDisbursementView;
