import { useState } from "react";
import styled from "styled-components";
import { first } from "lodash";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import Div from "./Div";
import Row from "./Row";
import Modal from "./Modal";
import Button from "./Button";
import PlaidButton from "../PlaidButton";
import PageLoader from "../PageLoader";
import TitleHeader from "../baseComponents/TitleHeader";
import HoorayCoin from "../../images/HoorayCoin";
import ErrorMessage from "./ErrorMessage";
import { makePartialPayment } from "../../utils/depositUtils";
import { COLORS } from "../global/colors";
import { currencyFormatter } from "../global/utils";
import { GenericObject, Deposit } from "../global/ModelInterfaces";
import { updateTenantDeposits } from "../../store/tenantInfo";

interface Props {
  prepaymentUUID: string;
  showModal: boolean;
  closeModal: () => void;
  paymentPlan: GenericObject;
  paymentType: string;
  onSuccess: (amount: number) => void;
}

const StyledButtonRow = styled(Row)`
  margin: 1rem 0rem;
`;

const StyledSuccess = styled(Row)`
  padding: 1rem;
  color: ${COLORS.primary};
  font-weight: ${(props) => props.theme.font_weight.semibold};
  font-size: 2rem;
`;

const StyledTotal = styled(Row)`
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;

const StyledBankRow = styled(Row)`
  margin-top: 1rem;
`;

const PaymentModal = ({
  showModal,
  closeModal,
  prepaymentUUID,
  paymentPlan,
  paymentType,
  onSuccess,
}: Props) => {
  const dispatch = useAppDispatch();
  const bankAccountList = useAppSelector((state) => state.bankInfo.objects);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [paymentError, setPaymentError] = useState("");
  const [paymentSuccess, setPaymentSuccess] = useState(false);

  const paymentAmount =
    paymentType === "monthly"
      ? paymentPlan.monthly_payment
      : paymentPlan.remaining_balance;

  const handlePartialPayment = () => {
    setPaymentLoading(true);
    const bankAccount = first(bankAccountList);
    const paymentSuccessCallback = (updatedDeposits: Deposit[]) => {
      onSuccess(paymentAmount);
      setPaymentSuccess(true);
      dispatch(updateTenantDeposits(updatedDeposits));
      setPaymentLoading(false);
    };
    const paymentFailureCallback = (err: Error) => {
      setPaymentError(
        `There was an error with your payment: ${err}. Please try again or reach out to someone from our team at support@rentable.com`
      );
      setPaymentLoading(false);
    };
    makePartialPayment(
      prepaymentUUID,
      bankAccount,
      paymentType,
      paymentSuccessCallback,
      paymentFailureCallback
    );
  };

  return (
    <Modal
      showModal={showModal}
      closeModal={() => {
        closeModal();
        setPaymentError("");
      }}
    >
      <Div width={{ default: 1 }}>
        {paymentError && <ErrorMessage>{paymentError}</ErrorMessage>}
        {paymentSuccess ? (
          <StyledSuccess>
            <Div
              width={{ sm: 1, md: 1 / 3 }}
              alignItems="center"
              justifyContent="center"
            >
              <HoorayCoin />
            </Div>
            <Div width={{ sm: 1, md: 2 / 3 }}>Your payment was successful!</Div>
          </StyledSuccess>
        ) : (
          <>
            {paymentLoading ? (
              <Div justifyContent="center" alignItems="center">
                <PageLoader />
                <span>
                  Your payment is processing, please do not refresh or close
                  this page.
                </span>
              </Div>
            ) : (
              <>
                <TitleHeader title="Confirm your payment" />
                {!bankAccountList.length ? (
                  <>
                    It looks like you don't have a bank account linked, please
                    link an account so that you can complete your payment.
                    <PlaidButton includeAssets={true} />
                  </>
                ) : (
                  <Div>
                    <Row>
                      <Div width={{ default: 1, lg: 2 / 12 }}>Amount:</Div>
                      <Div>{currencyFormatter.format(paymentAmount)}</Div>
                    </Row>
                    <Row>
                      <Div width={{ default: 1, lg: 2 / 12 }}>Fee:</Div>
                      <Div>
                        {currencyFormatter.format(paymentPlan.monthly_fee)}
                      </Div>
                    </Row>
                    <StyledTotal>
                      <Div width={{ default: 1, lg: 2 / 12 }}>Total:</Div>
                      <Div>
                        {currencyFormatter.format(
                          Number(paymentAmount) +
                            Number(paymentPlan.monthly_fee)
                        )}
                      </Div>
                    </StyledTotal>
                    <StyledBankRow>
                      <Div>
                        Bank Account: {bankAccountList[0].name} •••• •••• ••••{" "}
                        {bankAccountList[0].mask}
                      </Div>
                    </StyledBankRow>
                    <StyledButtonRow justifyContent="center">
                      <Div width={{ lg: 1 / 2 }}>
                        <Button
                          text="Submit Payment"
                          type="primary"
                          onClick={handlePartialPayment}
                        />
                      </Div>
                    </StyledButtonRow>
                  </Div>
                )}
              </>
            )}
          </>
        )}
      </Div>
    </Modal>
  );
};

export default PaymentModal;
