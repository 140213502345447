import { useState, useEffect, useMemo } from "react";
import { useAppDispatch } from "../../../store/hooks";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Div from "../../baseComponents/Div";
import Row from "../../baseComponents/Row";
import Button from "../../baseComponents/Button";
import FileUpload from "../../baseComponents/FileUpload";
import PageLoader from "../../PageLoader";
import useGetCurrentPrepayment from "../../../utils/useGetCurrentPrepayment";
import { useAppSelector } from "../../../store/hooks";
import {
  markPrepaymentRenterInsured,
  getRentersInsuranceURL,
} from "../../../utils/onboardingUtils";
import rentersInsuranceTypes from "../../../enums/RentersInsuranceTypes";
import { updateTenantInsurance } from "../../../store/tenantInfo";
import { GenericObject } from "../../global/ModelInterfaces";
import ErrorMessage from "../../baseComponents/ErrorMessage";

const StyledSkipButton = styled(Div)`
  cursor: pointer;
`;

interface Props {
  nextStep: () => void;
}

const RentersInsuranceStep = ({ nextStep }: Props) => {
  const { prepaymentUUID } = useParams<GenericObject>();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [showNext, setShowNext] = useState(false);
  const [baseURL, setBaseURL] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const successCallback = (res: any) => {
      setBaseURL(res.data.sure_renters_insurance_url);
      setLoading(false);
    };
    const failureCallback = (err: any) => {
      setErrorMessage(err.data);
      setLoading(false);
    };
    setLoading(true);
    getRentersInsuranceURL(successCallback, failureCallback);
  }, []);

  const { prepayment, loadingTenantInfo } =
    useGetCurrentPrepayment(prepaymentUUID);
  const company = prepayment.company;
  const currentUser = useAppSelector((state) => state.auth.user);

  const insuranceEnrollmentCallback = (enrollment: GenericObject) => {
    dispatch(updateTenantInsurance(enrollment));
    nextStep();
  };

  useEffect(() => {
    const handleEvent = (event: MessageEvent<any>) => {
      if (
        baseURL.includes(event.origin) &&
        event.data.actionType === "PURCHASE_RENTERS_SUCCESS"
      ) {
        markPrepaymentRenterInsured(
          prepayment,
          rentersInsuranceTypes.SURE_APP,
          insuranceEnrollmentCallback
        );
        // TODO log their policy number and stuff to the DB if possible from reporting
        setShowNext(true);
      }
    };
    // Bind the event listener
    window.addEventListener("message", handleEvent);
    return () => {
      window.removeEventListener("message", handleEvent);
    };
  }, [baseURL]);

  const showSkipButton = () => {
    return !prepayment.renters_insurance_required && !loading && !showNext;
  };

  const splitInterestedPartyName = (name: string) => {
    const companyNames: GenericObject = {};
    let firstName = "";
    let lastName = "";
    // if string has spaces, split the string into first/last names
    const stringArray = name.split(" ");
    if (stringArray.length > 1) {
      // add elements to firstName until limit reached (30 char max), then add the remaining elements to last name until limit reached (15 char max)
      stringArray.forEach((element) => {
        // only add elements to firstName if lastName is not yet being filled
        if ((firstName + element).length <= 30 && lastName.length === 0) {
          firstName += `${element} `;
        } else {
          lastName += `${element} `;
          lastName = lastName.slice(0, 15); // lastName is truncated at 15 chars
        }
      });
    } else {
      firstName = name;
      lastName = "";
    }
    companyNames["firstName"] = firstName;
    companyNames["lastName"] = lastName;
    return companyNames;
  };

  const generateIframeUrl = () => {
    if (!prepayment || !currentUser) {
      return baseURL;
    }
    const prepaymentData = `street_address=${prepayment.address_1}&unit=${prepayment.address_2}&city=${prepayment.city}&region=${prepayment.state}&postal=${prepayment.zip_code}&customID=${prepayment.prepayment_uuid}`;
    const userData = `&first_name=${currentUser.first_name}&last_name=${currentUser.last_name}&email=${currentUser.email}`;
    let completeURL = baseURL + prepaymentData + userData;
    if (prepayment.renters_insurance_interested_party_required) {
      const ipNames = splitInterestedPartyName(prepayment.building_name); // property name should be used for interested party
      const interestedPartyData = `&ip_first_name=${ipNames.firstName}&ip_last_name=${ipNames.lastName}&ip_line1=${company.street_address}&ip_city=${company.city}&ip_state=${company.state}&ip_zip=${company.zip_code}`;
      completeURL += interestedPartyData;
    }
    return completeURL;
  };

  // useMemo will re-invoke generateIframeUrl() only if one of the listed dependencies has changed
  const iframeURL = useMemo(
    () => generateIframeUrl(),
    [prepayment, currentUser, baseURL]
  );

  const handleSelfUpload = () => {
    markPrepaymentRenterInsured(
      prepayment,
      rentersInsuranceTypes.SELF_UPLOAD,
      insuranceEnrollmentCallback
    );
  };

  return (
    <>
      {(loading || loadingTenantInfo) && <PageLoader />}
      <Row>
        <Div width={{ default: 1 }} hidden={loading}>
          <iframe
            src={iframeURL}
            style={{
              height: "40rem",
              display: "block",
              border: "0px",
              margin: "0",
            }}
            onLoad={() => setLoading(false)}
            id="sureapp"
          ></iframe>
        </Div>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        <Div width={{ default: 1, lg: 10 / 12 }} hidden={showNext || loading}>
          <Row mt={{ default: "1rem" }} justifyContent="flex-start">
            <FileUpload
              prepayment={{ uuid: prepayment.prepayment_uuid, ...prepayment }}
              defaultText="Upload Existing Policy"
              confirmUpload={true}
              onSubmit={handleSelfUpload}
            />
          </Row>
        </Div>
        <Div width={{ default: 1, lg: 2 / 12 }} hidden={!showSkipButton()}>
          <Row mt={{ default: "1rem" }} justifyContent="flex-end">
            <StyledSkipButton onClick={nextStep}>Skip</StyledSkipButton>
          </Row>
        </Div>
        <Row mt={{ default: "1rem" }} justifyContent="flex-end">
          <Div width={{ default: 1, lg: 2 / 12 }} hidden={!showNext}>
            <Button onClick={nextStep}>Next</Button>
          </Div>
        </Row>
      </Row>
    </>
  );
};

export default RentersInsuranceStep;
