const prepaymentOverviewGeneralTabSections = {
  GENERAL_INFO: "generalInfo",
  INVITE_INFO: "inviteInfo",
  DEPOSITS_INFO: "depositsInfo",
  PAYMENT_INFO: "paymentInfo",
  PAYMENT_PLAN_INFO: "paymentPlanInfo",
  BANK_INFO: "bankInfo",
  ADDITIONAL_FUNDS_INFO: "additionalFundsInfo",
  PARTIAL_REFUND_INFO: "partialRefundInfo",
  DISBURSEMENT_INFO: "disbursementInfo",
  DEDUCTIONS_INFO: "deductionsInfo",
};

export default prepaymentOverviewGeneralTabSections;
