import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Div from "../../baseComponents/Div";
import Row from "../../baseComponents/Row";
import FeePaymentSubStep from "./FeePaymentSubStep";
import SecurityDepositPaymentSubstep from "./SecurityDepositPaymentSubstep";
import LastMonthsRentPaymentSubstep from "./LastMonthsRentPaymentSubstep";
import paymentSubsteps from "../../../enums/paymentSubstep";
import prepaymentStatuses from "../../../enums/prepaymentStatuses";
import useGetCurrentPrepayment from "../../../utils/useGetCurrentPrepayment";
import {
  TenantPrepaymentInfo,
  Fee,
  GenericObject,
} from "../../global/ModelInterfaces";
import { BANK_LINK_STEP } from "../../../utils/onboardingUtils";
interface Props {
  nextStep: () => void;
  navigateToStep: (step: string) => void;
}

const NavigationRow = styled(Row)`
  margin-bottom: 1rem;
`;

const PaymentStep = ({ nextStep, navigateToStep }: Props) => {
  const { prepaymentUUID } = useParams<GenericObject>();

  const { prepayment } = useGetCurrentPrepayment(prepaymentUUID);
  const prepaymentInfo = prepayment;

  const areFeesPaid = () => {
    return (
      prepaymentInfo.fees.length === 0 ||
      prepaymentInfo.fees.every(
        (fee: Fee) => fee.status !== prepaymentStatuses.PENDING
      )
    );
  };
  const [paymentSubstep, setPaymentSubstep] = useState<string>("");

  const checkPaymentSubstep = (prepaymentInfo: TenantPrepaymentInfo) => {
    // Check status of fees
    if (!areFeesPaid()) {
      return paymentSubsteps.FEES;
    }
    // Check status of LMR payment
    const lastMonthsRent = prepaymentInfo.deposits.last_months_rent;
    if (
      !!lastMonthsRent &&
      lastMonthsRent.status_id === prepaymentStatuses.PENDING
    ) {
      return paymentSubsteps.LAST_MONTHS_RENT;
    }
    // Check status of SD payment
    const securityDeposit = prepaymentInfo.deposits.security_deposit;
    if (
      !!securityDeposit &&
      securityDeposit.status_id === prepaymentStatuses.PENDING
    ) {
      return paymentSubsteps.SECURITY_DEPOSIT;
    }

    return paymentSubsteps.COMPLETE;
  };

  const updatePaymentSubstep = () => {
    const currentSubstep = checkPaymentSubstep(prepaymentInfo);
    setPaymentSubstep(currentSubstep);
  };

  const renderPaymentSubStep = (paymentSubstep: string) => {
    switch (paymentSubstep) {
      case paymentSubsteps.FEES:
        return <FeePaymentSubStep />;
      case paymentSubsteps.LAST_MONTHS_RENT:
        return <LastMonthsRentPaymentSubstep />;
      case paymentSubsteps.SECURITY_DEPOSIT:
        return <SecurityDepositPaymentSubstep />;
      case paymentSubsteps.COMPLETE:
        nextStep();
        return <></>;
      default:
        return <Div>Default step</Div>;
    }
  };

  useEffect(() => {
    updatePaymentSubstep();
  }, [prepaymentInfo.deposits]);

  return (
    <Div>
      <NavigationRow justifyContent="flex-end">
        <a
          onClick={() => {
            navigateToStep(BANK_LINK_STEP);
          }}
        >
          Manage Bank Account
        </a>
      </NavigationRow>
      <Div width={{ default: 1 }}>{renderPaymentSubStep(paymentSubstep)}</Div>
    </Div>
  );
};
export default PaymentStep;
