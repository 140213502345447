import axios from "axios";
import {
  CreateDepositRequest,
  Option,
} from "../../components/global/ModelInterfaces";
import { formatDateAsValue } from "../../components/global/utils";
import { head } from "lodash";

export const retrievePrepaymentsByEmail = (
  email: string,
  successCallback: Function,
  failureCallback: Function
) => {
  const url = "/nucleus/api/retrieve-prepayments-by-email";

  axios
    .post(url, {
      email: email,
    })
    .then((resp) => {
      const data = resp.data;
      successCallback(data.prepayments);
    })
    .catch(() => {
      failureCallback();
    });
};

export const retrievePrepaymentDetails = (
  prepaymentUUID: string,
  successCallback: Function,
  failureCallback: Function
) => {
  const url = "/nucleus/api/retrieve-prepayment-details";
  axios
    .post(url, {
      prepayment_uuid: prepaymentUUID,
    })
    .then((res) => {
      successCallback(res.data);
    })
    .catch((res) => {
      failureCallback(res.data);
    });
};

export const addDepositToPrepayment = (
  prepaymentUUID: string,
  amount: number,
  type: Option | null,
  successCallback: Function,
  failureCallback: Function
) => {
  const url = "/nucleus/api/add-deposit-to-prepayment";
  axios
    .post(url, {
      prepayment_uuid: prepaymentUUID,
      deposit_amount: amount,
      deposit_type: type,
    })
    .then((res) => {
      successCallback(res.data.updated_deposit_info);
    })
    .catch((res) => {
      const data = res.data;
      failureCallback(data.error);
    });
};

export const depositOptions = [
  { label: "Security Deposit", id: "1" },
  { label: "Last Month's Rent", id: "2" },
  // { label: "Key Fee", id: "3" },
];

export const updatePrepaymentDates = (
  prepaymentUUID: string,
  successCallback: Function,
  failureCallback: Function,
  newStartDate: string,
  newEndDate?: string,
  monthToMonth?: Boolean
) => {
  const url = "/nucleus/api/update-prepayment-dates";
  axios
    .post(url, {
      prepayment_uuid: prepaymentUUID,
      start_date: newStartDate ? formatDateAsValue(newStartDate) : newStartDate,
      end_date: newEndDate ? formatDateAsValue(newEndDate) : newEndDate,
      month_to_month: monthToMonth,
    })
    .then((res) => {
      successCallback(res.data);
    })
    .catch((err: any) => {
      failureCallback(err.data);
    });
};

export const updatePrepaymentUnit = (
  prepaymentUUID: string,
  newUnitUUID: string,
  successCallback: Function,
  failureCallback: Function
) => {
  const url = "/nucleus/api/move-prepayment-to-new-unit";
  axios
    .post(url, {
      prepayment_uuid: prepaymentUUID,
      unit_uuid: newUnitUUID,
    })
    .then((res) => {
      successCallback(res.data);
    })
    .catch((err: any) => {
      failureCallback(err.data);
    });
};

export const markDisbursementCheckCashed = (
  checkUUID: string,
  prepaymentUUID: string,
  successCallback: Function,
  failureCallback: Function
) => {
  const url = "/nucleus/api/mark-check-cashed";
  axios
    .post(url, {
      prepayment_uuid: prepaymentUUID,
      check_uuid: checkUUID,
    })
    .then((res) => {
      successCallback(res.data);
    })
    .catch((err: any) => {
      failureCallback(err.data);
    });
};

export const createDeposit = (
  depositInfo: CreateDepositRequest,
  companyUUID: string,
  successCallback: Function,
  failureCallback: Function
) => {
  const url = "/nucleus/api/create-single-deposit";
  axios
    .post(url, {
      tenant_email: depositInfo.email,
      tenant_first_name: depositInfo.firstName,
      tenant_last_name: depositInfo.lastName,
      tenant_phone: depositInfo.phoneNumber,
      start_date: depositInfo.startDate,
      end_date: depositInfo.endDate,
      unit_uuid: depositInfo.unitUUID,
      security_deposit_amount: depositInfo.securityDeposit,
      last_months_rent_amount: depositInfo.lastMonthsRent,
      security_deposit_interest_amount: depositInfo.securityDepositInterest,
      last_months_rent_interest_amount: depositInfo.lastMonthsRentInterest,
      paid: depositInfo.userPaid,
      company_uuid: companyUUID,
    })
    .then((res) => {
      successCallback(res.data);
    })
    .catch((err) => {
      failureCallback(err.data);
    });
};

export const retrieveDepositCSVFields = (
  companyUUID: string,
  successCallback: Function,
  failureCallback: Function
) => {
  const url = "/nucleus/api/retrieve-deposit-csv-fields";
  axios
    .post(url, { company_uuid: companyUUID })
    .then((res) => {
      successCallback(res.data);
    })
    .catch((err) => {
      failureCallback(err.data);
    });
};

export const uploadDepositCSV = (
  csv: File,
  companyUUID: string,
  fundDeposits: boolean,
  bankAccountUUID: string,
  successCallback: Function,
  failureCallback: Function
) => {
  const csvData = new FormData();
  csvData.append("file", csv);
  csvData.append("fund_deposits", String(fundDeposits));
  csvData.append("bank_uuid", bankAccountUUID);
  const url = "/nucleus/api/upload-deposit-csv";
  axios
    .post(url, csvData, { headers: { company_uuid: companyUUID } })
    .then((res) => {
      successCallback(res.data.message);
    })
    .catch((err) => {
      failureCallback(err.data.message);
    });
};

export const retrievePrepaymentInviteUrl = (
  prepaymentUUID: string,
  successCallback: Function,
  failureCallback: Function
) => {
  const url = "/nucleus/api/retrieve-tenant-invite";
  axios
    .post(url, {
      prepayment_uuid: prepaymentUUID,
    })
    .then((res) => {
      successCallback(res.data);
    })
    .catch((err: any) => {
      failureCallback(err.data);
    });
};

export const unrestrictUser = (
  prepaymentUUID: string,
  successCallback: Function,
  failureCallback: Function
) => {
  const url = "/nucleus/api/unrestrict-user";
  axios
    .post(url, {
      prepayment_uuid: prepaymentUUID,
    })
    .then((res) => {
      successCallback(res.data);
    })
    .catch((err: any) => {
      failureCallback(err.data);
    });
};

export const manuallySendDisbursementCheck = (
  delayedDisbursementUUID: string,
  address1: string,
  address2: string,
  city: string,
  state: string,
  zipCode: string,
  successCallback: Function,
  failureCallback: Function
) => {
  const url = "/nucleus/api/manually-send-disbursement-check";
  axios
    .post(url, {
      delayed_disbursement_uuid: delayedDisbursementUUID,
      address_1: address1,
      address_2: address2,
      city: city,
      state: state,
      zip_code: zipCode,
    })
    .then((res) => {
      successCallback(res.data);
    })
    .catch((err: any) => {
      failureCallback(err.data);
    });
};
