import styled from "styled-components";
import Row from "../../../components/baseComponents/Row";
import Div from "../../../components/baseComponents/Div";
import prepaymentOverviewGeneralTabSections from "../../../enums/prepaymentOverviewGeneralTabSections";
import SideNavLink from "../SideNavLink";
import { FaHouseUser } from "react-icons/fa";

const StyledNav = styled(Div)`
  min-height: 100vh;
  flex: 0 0 auto;
  font-size: ${(props) => props.theme.font_size.bodyMD};
  font-weight: ${(props) => props.theme.font_weight.semibold};
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.backgroundSecondary};
`;

const StyledTitleItem = styled(Div)`
  background-color: ${(props) => props.theme.colors.backgroundPrimary};
  color: ${(props) => props.theme.colors.white};
  white-space: pre;
  border-bottom: ${(props) => "1px solid " + props.theme.colors.grey20};
`;

const StyledIcon = styled(Div)`
  color: ${(props) => props.theme.colors.white};
  font-size: ${(props) => props.theme.font_size.headlineSM};
`;

interface Props {
  currentSection: string;
  onSelect: Function;
}

const PrepaymentNavbar = ({ currentSection, onSelect }: Props) => {
  return (
    <>
      <StyledNav>
        <StyledTitleItem
          pl={{ md: 3 }}
          pr={{ md: 3 }}
          pt={{ md: 4 }}
          pb={{ default: 4 }}
        >
          <Row
            alignItems="center"
            flexWrap="noWrap"
            justifyContent="flex-start"
          >
            <StyledIcon>
              <FaHouseUser />
            </StyledIcon>
            Tenant Navigation
          </Row>
        </StyledTitleItem>
        <SideNavLink
          linkText={"General Info"}
          variant={"secondary"}
          onClick={() =>
            onSelect(prepaymentOverviewGeneralTabSections.GENERAL_INFO)
          }
          isActive={
            currentSection === prepaymentOverviewGeneralTabSections.GENERAL_INFO
          }
        />
        <SideNavLink
          linkText={"Additional Funds"}
          variant={"secondary"}
          onClick={() =>
            onSelect(prepaymentOverviewGeneralTabSections.ADDITIONAL_FUNDS_INFO)
          }
          isActive={
            currentSection ===
            prepaymentOverviewGeneralTabSections.ADDITIONAL_FUNDS_INFO
          }
        />{" "}
        <SideNavLink
          linkText={"Bank Info"}
          variant={"secondary"}
          onClick={() =>
            onSelect(prepaymentOverviewGeneralTabSections.BANK_INFO)
          }
          isActive={
            currentSection === prepaymentOverviewGeneralTabSections.BANK_INFO
          }
        />
        <SideNavLink
          linkText={"Deductions Info"}
          variant={"secondary"}
          onClick={() =>
            onSelect(prepaymentOverviewGeneralTabSections.DEDUCTIONS_INFO)
          }
          isActive={
            currentSection ===
            prepaymentOverviewGeneralTabSections.DEDUCTIONS_INFO
          }
        />
        <SideNavLink
          linkText={"Deposits Info"}
          variant={"secondary"}
          onClick={() =>
            onSelect(prepaymentOverviewGeneralTabSections.DEPOSITS_INFO)
          }
          isActive={
            currentSection ===
            prepaymentOverviewGeneralTabSections.DEPOSITS_INFO
          }
        />
        <SideNavLink
          linkText={"Disbursement Info"}
          variant={"secondary"}
          onClick={() =>
            onSelect(prepaymentOverviewGeneralTabSections.DISBURSEMENT_INFO)
          }
          isActive={
            currentSection ===
            prepaymentOverviewGeneralTabSections.DISBURSEMENT_INFO
          }
        />
        <SideNavLink
          linkText={"Invite Info"}
          variant={"secondary"}
          onClick={() =>
            onSelect(prepaymentOverviewGeneralTabSections.INVITE_INFO)
          }
          isActive={
            currentSection === prepaymentOverviewGeneralTabSections.INVITE_INFO
          }
        />
        <SideNavLink
          linkText={"Partial Refund Info"}
          variant={"secondary"}
          onClick={() =>
            onSelect(prepaymentOverviewGeneralTabSections.PARTIAL_REFUND_INFO)
          }
          isActive={
            currentSection ===
            prepaymentOverviewGeneralTabSections.PARTIAL_REFUND_INFO
          }
        />{" "}
        <SideNavLink
          linkText={"Payment Info"}
          variant={"secondary"}
          onClick={() =>
            onSelect(prepaymentOverviewGeneralTabSections.PAYMENT_INFO)
          }
          isActive={
            currentSection === prepaymentOverviewGeneralTabSections.PAYMENT_INFO
          }
        />
        <SideNavLink
          linkText={"Payment Plan Info"}
          variant={"secondary"}
          onClick={() =>
            onSelect(prepaymentOverviewGeneralTabSections.PAYMENT_PLAN_INFO)
          }
          isActive={
            currentSection ===
            prepaymentOverviewGeneralTabSections.PAYMENT_PLAN_INFO
          }
        />
      </StyledNav>
    </>
  );
};

export default PrepaymentNavbar;
