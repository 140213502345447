import { useState } from "react";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { RootState } from "../..";
import { useParams, useHistory } from "react-router-dom";
import styled from "styled-components";
import { updatePrepayment } from "../../store/prepayments";
import Div from "../baseComponents/Div";
import Row from "../baseComponents/Row";
import BackButton from "../baseComponents/BackButton";
import Button from "../baseComponents/Button";
import Container from "../baseComponents/Container";
import CurrencyInput from "../baseComponents/CurrencyInput";
import PageLoader from "../PageLoader";
import ErrorMessage from "../baseComponents/ErrorMessage";
import { cssBreakpoints } from "../global/theme";
import { currencyFormatter } from "../global/utils";
import { GenericObject, Prepayment } from "../global/ModelInterfaces";
import { requestAdditionalFunds } from "../../utils/depositUtils";
import prepaymentStatuses from "../../enums/prepaymentStatuses";

const StyledForm = styled(Row)`
  ${cssBreakpoints("margin-top", [{ sm: "2rem" }])}
`;

const StyledButton = styled(Button)`
  ${cssBreakpoints("margin-top", [{ sm: "2rem" }])}
`;
const StyledTitle = styled(Row)`
  ${cssBreakpoints("margin-bottom", [{ sm: "2rem" }])}
`;

const StyledSummaryBox = styled(Div)`
  border: 1px solid black;
  padding: 2rem;
  ${cssBreakpoints("margin-bottom", [{ sm: "2rem" }])}
`;

const StyledLabel = styled(Div)`
  font-weight: 600;
  font-size: ${(props) => props.theme.font_size.headlineSM};
`;

const StyledTotal = styled(Div)`
  font-weight: 600;
`;

const AdditionalFundsView = () => {
  const { prepaymentUUID } = useParams<GenericObject>();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [securityDepositAmount, setSecurityDepositAmount] = useState(0.0);
  const [lastMonthsRentAmount, setLastMonthsRentAmount] = useState(0.0);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const prepaymentSlice: { loading: boolean; objects: Prepayment[] } =
    useAppSelector((state: RootState) => state.prepayments);

  const loadingPrepayments = prepaymentSlice.loading;
  const selectedPrepayment = prepaymentSlice.objects.find(
    (prepayment: Prepayment) => prepayment.uuid === prepaymentUUID
  );

  const handleAdditionalFundsRequest = () => {
    setLoading(true);
    if (selectedPrepayment) {
      const successCallBack = (updatedPrepayment: GenericObject) => {
        dispatch(updatePrepayment(updatedPrepayment));
        setSuccess(true);
        setLoading(false);
      };
      const errorCallback = (errorMessage: string) => {
        setError(`There was an error creating the refund: ${errorMessage}`);
        setLoading(false);
      };
      requestAdditionalFunds(
        selectedPrepayment.uuid,
        securityDepositAmount,
        lastMonthsRentAmount,
        successCallBack,
        errorCallback
      );
    }
  };

  const returnToPrepayment = () => {
    if (selectedPrepayment) {
      history.push(`/dashboard/tenant/${selectedPrepayment.uuid}`);
    }
  };

  if (loadingPrepayments) {
    return (
      <Div>
        <PageLoader />
      </Div>
    );
  } else if (!selectedPrepayment) {
    history.push("/forbidden");
  } else if (!selectedPrepayment.deposits) {
    returnToPrepayment();
  } else if (success) {
    return (
      <Container>
        <BackButton
          text={"Back to Deposit"}
          returnUrl={`/dashboard/tenant/${selectedPrepayment.uuid}`}
        />
        <StyledForm justifyContent="center">
          <StyledLabel width={{ default: 1 }} alignItems="center">
            Successfully Requested Additional Deposit Funds
          </StyledLabel>
          <Div width={{ default: 1, lg: 1 / 3 }} alignItems="center">
            <StyledButton
              type="primary"
              text={`Return to Deposit`}
              onClick={returnToPrepayment}
            />
          </Div>
        </StyledForm>
      </Container>
    );
  } else {
    return (
      <Container>
        <BackButton
          text={"Back to Deposit"}
          returnUrl={`/dashboard/tenant/${selectedPrepayment.uuid}`}
        />
        <StyledForm justifyContent="center">
          <Div width={{ default: 1, lg: 1 / 2 }} alignItems={"center"}>
            <StyledTitle>
              <Div width={{ default: 1 }} alignItems={"center"}>
                <h2>Request Additional Deposit Funds</h2>
              </Div>
            </StyledTitle>
            <Row justifyContent="center">
              <StyledSummaryBox>
                <Row justifyContent="center">
                  {selectedPrepayment.deposits.security_deposit &&
                    selectedPrepayment.deposits.security_deposit.status_id ===
                      prepaymentStatuses.ACTIVE && (
                      <Div width={{ default: 1, lg: 1 / 2 }}>
                        <StyledLabel width={{ default: 1 }}>
                          Security Deposit:
                        </StyledLabel>
                        <Div>Current Amount:</Div>
                        <Div width={{ default: 1 / 2 }}>
                          {currencyFormatter.format(
                            selectedPrepayment.deposits.security_deposit.amount
                          )}
                        </Div>
                        <Div width={{ default: 1 }}>
                          <Row>
                            Requested Security Deposit Funds:
                            <CurrencyInput
                              onChange={setSecurityDepositAmount}
                            />
                          </Row>
                        </Div>
                        <StyledTotal
                          width={{ default: 1 }}
                        >{`Security Deposit Total After Top Up: ${currencyFormatter.format(
                          parseFloat(
                            selectedPrepayment.deposits.security_deposit.amount
                          ) + securityDepositAmount
                        )}`}</StyledTotal>
                      </Div>
                    )}
                  {selectedPrepayment.deposits.last_months_rent &&
                    selectedPrepayment.deposits.last_months_rent.status_id ===
                      prepaymentStatuses.ACTIVE && (
                      <Div width={{ default: 1, lg: 1 / 2 }}>
                        <StyledLabel width={{ default: 1 }}>
                          Last Month's Rent:
                        </StyledLabel>
                        <Div>Current Amount:</Div>
                        <Div width={{ default: 1 / 2 }}>
                          {currencyFormatter.format(
                            selectedPrepayment.deposits.last_months_rent.amount
                          )}
                        </Div>
                        <Div width={{ default: 1 }}>
                          <Row>
                            Requested Last Month's Rent Funds:
                            <CurrencyInput onChange={setLastMonthsRentAmount} />
                          </Row>
                        </Div>
                        <StyledTotal
                          width={{ default: 1 }}
                        >{`Last Month's Rent Total After Top Up: ${currencyFormatter.format(
                          parseFloat(
                            selectedPrepayment.deposits.last_months_rent.amount
                          ) + lastMonthsRentAmount
                        )}`}</StyledTotal>
                      </Div>
                    )}
                </Row>
              </StyledSummaryBox>
            </Row>
            <Row justifyContent="center">
              <Div width={{ default: 1, lg: 10 / 12 }}>
                <StyledButton
                  disabled={
                    !(
                      (lastMonthsRentAmount || 0) + (securityDepositAmount || 0)
                    )
                  }
                  type="primary"
                  text={`Request Additional Funds`}
                  onClick={handleAdditionalFundsRequest}
                  loading={loading}
                />
              </Div>
            </Row>
            {error && (
              <Row justifyContent="center">
                <ErrorMessage>{error}</ErrorMessage>
              </Row>
            )}
          </Div>
        </StyledForm>
      </Container>
    );
  }
  return (
    <Div>
      <PageLoader />
    </Div>
  );
};

export default AdditionalFundsView;
