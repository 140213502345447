import { Route, Switch } from "react-router-dom";
import { Redirect, useLocation } from "react-router";

import AccountPage from "../../pages/AccountPage";
import ForbiddenView from "../baseComponents/ForbiddenView";
import Dashboard from "./Dashboard/Dashboard";
import PrepaymentView from "./Dashboard/PrepaymentView";
import TenantOnboarding from "./TenantOnboarding/TenantOnboarding";
import TenantOffboarding from "./TenantOffboarding/TenantOffboarding";
import BankDepositDisbursementView from "./TenantOffboarding/BankDepositDisbursementView";
import CheckDisbursementView from "./TenantOffboarding/CheckDisbursementView";
import CheckDisbursementSuccessView from "./TenantOffboarding/CheckDisbursementSuccessView";
import PayWithCardPage from "../../pages/PayWithCardPage";
import CardPaymentConfirmed from "../../pages/CardPaymentConfirmed";
import PersonalVerifyPage from "../../pages/PersonalVerifyPage";
import ExplainVerification from "./TenantOnboarding/ExplainVerification";
import AdditionalFundsPayment from "../../pages/AdditionalFundsPayment";
import UpdatePlaidCredentialsPage from "../../pages/UpdatePlaidCredentialsPage";
import MissingPage from "../../pages/MissingPage";
import { GenericObject } from "../global/ModelInterfaces";

const RoutesTenant = () => {
  const location = useLocation<GenericObject>();

  return (
    <Switch>
      <Route exact path="/myaccount" component={AccountPage} />
      <Route
        exact={true}
        path="/personal-verification"
        component={PersonalVerifyPage}
      />
      <Route exact path="/forbidden" component={ForbiddenView} />
      <Route
        exact={true}
        path="/pay-with-card/:prepaymentUUID"
        component={PayWithCardPage}
      />
      <Route
        exact={true}
        path="/card-payment-confirmed/:prepaymentUUID"
        component={CardPaymentConfirmed}
      />
      <Route
        exact={true}
        path="/tenant/onboarding/:prepaymentUUID"
        component={() => <TenantOnboarding />}
      />
      <Route
        exact={true}
        path="/tenant/offboarding/bank-deposit/:prepaymentUUID"
        component={() => <BankDepositDisbursementView />}
      />
      <Route
        exact={true}
        path="/tenant/offboarding/send-check/:prepaymentUUID"
        component={() => <CheckDisbursementView />}
      />
      <Route
        exact={true}
        path="/tenant/offboarding/check-success"
        component={() => <CheckDisbursementSuccessView />}
      />
      <Route
        exact={true}
        path="/tenant/offboarding/:prepaymentUUID"
        component={() => <TenantOffboarding />}
      />
      <Route
        exact={true}
        path="/tenant/onboarding/verification-explanation"
        component={ExplainVerification}
      />
      <Route
        exact={true}
        path="/tenant/additional-funds/:prepaymentUUID"
        component={AdditionalFundsPayment}
      />
      <Route
        exact={true}
        path="/update-bank-credentials"
        component={() => (
          <UpdatePlaidCredentialsPage
            returnUrl={location.state?.returnUrl}
            successUrl={location.state?.successUrl}
          />
        )}
      />
      <Route exact={true} path="/404" component={MissingPage} />
      <Route path="/dashboard/:prepaymentUUID" component={PrepaymentView} />
      <Route path="/dashboard" component={Dashboard} />
      <Redirect to="/dashboard" />
    </Switch>
  );
};

export default RoutesTenant;
