import Modal from "./Modal";
import Button from "./Button";
import Row from "./Row";
import Div from "./Div";
import ErrorMessage from "../baseComponents/ErrorMessage";
import styled from "styled-components";
import { useEffect, useState } from "react";

/**
 * ConfirmationModal — react component to display a modal confirmation window
 *
 * @confirmationOpen {boolean} : show/hide confirmation modal
 * @onClose {function} : callback on close modal
 * @onConfirm {function} : callback on confirm button pressed
 * @message {string} : text to show on modal
 *
 **/

interface ConfirmationModalProps {
  confirmationOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  errorMessage?: string;
  message?: string;
  buttonText?: string;
}

const StyledMessage = styled(Div)`
  font-size: ${(props) => props.theme.font_size.headlineXS};
  font-weight: ${(props) => props.theme.font_weight.semibold};
  margin-bottom: 1rem;
  white-space: pre-line;
`;

const StyledModal = styled(Modal)`
  padding: 1rem;
`;

const ConfirmationModal = ({
  confirmationOpen,
  onClose,
  onConfirm,
  errorMessage,
  message,
  buttonText,
}: ConfirmationModalProps) => {
  const [loading, setLoading] = useState(false);

  const handleConfirm = () => {
    setLoading(true);
    onConfirm();
  };

  const handleClose = () => {
    if (!loading || errorMessage) {
      onClose();
    }
  };

  useEffect(() => {
    if (!confirmationOpen) {
      setLoading(false);
    }
  }, [confirmationOpen]);

  return (
    <StyledModal showModal={confirmationOpen} closeModal={handleClose}>
      <Row justifyContent="center">
        <StyledMessage width={{ default: 1 }} alignItems="center">
          {message ??
            "Please confirm you would like to take the previous action"}
        </StyledMessage>
        {errorMessage && (
          <ErrorMessage textAlign={"center"}>{errorMessage}</ErrorMessage>
        )}
        {!errorMessage && (
          <Div width={{ default: 1, lg: 8 / 12 }}>
            <Button onClick={handleConfirm} loading={loading}>
              {buttonText ? buttonText : "Confirm"}
            </Button>
          </Div>
        )}
      </Row>
    </StyledModal>
  );
};

export default ConfirmationModal;
