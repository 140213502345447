import { useEffect, useState } from "react";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import { noop, some } from "lodash";
import styled from "styled-components";
import { disburseDelayedDisbursement } from "../../../utils/disbursementUtils";
import { updateTenantOffboarding } from "../../../store/tenantInfo";
import PlaidButton from "../../PlaidButton";
import Button from "../../baseComponents/Button";
import Label from "../../baseComponents/Label";
import Row from "../../baseComponents/Row";
import Div from "../../baseComponents/Div";
import BackButton from "../../baseComponents/BackButton";
import PageLoader from "../../PageLoader";
import IDVerificationButton from "../../IDVerificationButton";
import PaymentProcessorRestricted from "../PaymentProcessorRestricted";
import { GenericObject } from "../../global/ModelInterfaces";
import { RootState } from "../../..";
import {
  createUserPersonalInfo,
  setUserCitizenship,
} from "../../../utils/accountUtils";
import useGetBankAccount from "../../../utils/useGetBankAccount";
import useGetCurrentPrepayment from "../../../utils/useGetCurrentPrepayment";
import DisbursementSummary from "./DisbursementSummary";
import ErrorMessage from "../../baseComponents/ErrorMessage";

const StyledTitle = styled(Row)`
  font-size: ${(props) => props.theme.font_size.headlineSM};
  font-weight: ${(props) => props.theme.font_weight.semibold};
  margin: 2rem 0;
`;

const StyledContainer = styled(Row)`
  margin-top: 1rem;
`;

const StyledButtonContainer = styled(Div)`
  margin-top: 1rem;
`;

const BankDepositDisbursementView = () => {
  const { prepaymentUUID } = useParams<GenericObject>();
  const [disbursing, setDisbursing] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useAppDispatch();
  const { bankAccount } = useGetBankAccount();
  const tenantInfoSlice: { loading: Boolean; objects: GenericObject } =
    useAppSelector((state: RootState) => state.tenantInfo);

  const { loadingTenantInfo, offboarding } =
    useGetCurrentPrepayment(prepaymentUUID);
  const personalInfo = tenantInfoSlice.objects.personalInfo;
  const [readyForVerification, setReadyForVerification] =
    useState<boolean>(false);
  const [readyForTransfer, setReadyForTransfer] = useState(false);
  const [isCitizen, setIsCitizen] = useState<boolean | null>(null);

  const handleIDVerification = (link_session_id: string) => {
    createUserPersonalInfo(link_session_id)
      .then((res) => {
        if (res.verified) {
          setReadyForTransfer(true);
        }
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  useEffect(() => {
    if (success) {
      dispatch(updateTenantOffboarding(prepaymentUUID));
    }
  }, [success]);

  useEffect(() => {
    if (personalInfo) {
      setReadyForTransfer(some(personalInfo));
    }
  }, [personalInfo]);

  const handleFundsTransfer = () => {
    setDisbursing(true);
    const successCallback = () => {
      setSuccess(true);
      setDisbursing(false);
    };

    const failureCallback = (errorMessage: string) => {
      setError(errorMessage);
      setDisbursing(false);
    };

    disburseDelayedDisbursement(
      offboarding.prepayment,
      successCallback,
      failureCallback
    );
  };

  const handleCitizenshipClick = (isCitizen: boolean) => {
    setIsCitizen(isCitizen);
    setUserCitizenship(isCitizen)
      .then(() => {
        setReadyForVerification(true);
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  if (loadingTenantInfo) {
    return <PageLoader />;
  }

  if (!offboarding && !loadingTenantInfo && !success) {
    return <Redirect to={`/dashboard`} />;
  }

  const LinkOrConfirmBank = (bankAccount: any) => {
    return (
      <>
        <Row mt={{ default: 5 }}>
          <Label width={{ default: 1 }} alignItems="center">
            {!bankAccount
              ? "Please link a bank account so you can receive your deposits"
              : "Please confirm your micro-deposits before continuing"}
          </Label>
        </Row>
        <Row justifyContent="center">
          <StyledButtonContainer>
            <PlaidButton onSuccess={noop} />
          </StyledButtonContainer>
        </Row>
      </>
    );
  };

  const InitiateTransfer = (
    bankAccount: any,
    disbursing: boolean,
    handleFundsTransfer: any
  ) => {
    return (
      <>
        <Label mt={{ default: 5 }}>
          Click the button below to transfer your funds to the{" "}
          {bankAccount.name} account ending in {bankAccount.mask}. This transfer
          will take 3-5 business days.
        </Label>
        <Row justifyContent="center">
          <StyledButtonContainer width={{ default: 1, lg: 6 / 12 }}>
            <Button
              text="Transfer funds"
              onClick={handleFundsTransfer}
              loading={disbursing}
            />
          </StyledButtonContainer>
        </Row>
      </>
    );
  };

  const CheckCitizenship = () => {
    return (
      <Row justifyContent="center" mt={{ default: 5 }}>
        <Div alignItems="center" width={{ sm: 1 }}>
          <Label mb={{ default: 2 }}>
            Before transferring your funds we need to confirm your identity.
          </Label>
          <Label mb={{ default: 3 }}>Are you a U.S. Citizen?</Label>
          <Row mb={{ default: 3 }}>
            <Div width={{ default: 6 / 12 }}>
              <Button
                type={"secondary"}
                disabled={isCitizen !== null && isCitizen}
                onClick={() => {
                  handleCitizenshipClick(true);
                }}
              >
                Yes
              </Button>
            </Div>
            <Div width={{ default: 6 / 12 }}>
              <Button
                type={"secondary"}
                disabled={isCitizen !== null && !isCitizen}
                onClick={() => {
                  handleCitizenshipClick(false);
                }}
              >
                No
              </Button>
            </Div>
          </Row>
        </Div>
      </Row>
    );
  };

  const CreatePersonalInfo = (handleIDVerification: any) => {
    return (
      <Row justifyContent="center" mt={{ default: 5 }}>
        <Label>
          Before transferring your funds we need to confirm your identity.
        </Label>
        <StyledButtonContainer width={{ default: 1, lg: 6 / 12 }}>
          <IDVerificationButton onSuccess={handleIDVerification} />
        </StyledButtonContainer>
      </Row>
    );
  };

  const TransferSuccess = () => {
    const history = useHistory();
    return (
      <>
        <StyledTitle justifyContent="center" alignItems="center">
          Transfer Complete!
        </StyledTitle>
        <Row>
          <Div>
            You're all set! You should see your funds hit your account in the
            next few days. If you have any questions, please reach out to
            support@rentable.com.
          </Div>
        </Row>
        <Row justifyContent="center">
          <StyledButtonContainer width={{ default: 1, lg: 6 / 12 }}>
            <Button onClick={() => history.push("/dashboard")}>
              Return to Dashboard
            </Button>
          </StyledButtonContainer>
        </Row>
      </>
    );
  };

  return (
    <StyledContainer justifyContent="center">
      <Div width={{ default: 1, lg: 6 / 12 }}>
        <Row>
          <BackButton text="Go Back" returnUrl="/tenant/offboarding" />
        </Row>
        <StyledTitle justifyContent="center" alignItems="flex-start">
          Receive Your Funds Via Direct Deposit
        </StyledTitle>
        <DisbursementSummary offboarding={offboarding} />
        {(() => {
          if (personalInfo?.payment_processor_restricted) {
            return (
              <Div mt={{ default: 3 }}>
                <PaymentProcessorRestricted />
              </Div>
            );
          } else if (success) {
            return TransferSuccess();
          } else if (error) {
            return (
              <Div mt={{ default: 3 }} alignItems="center">
                <ErrorMessage>
                  {error} Please refresh or contact support@rentable.com if this
                  problem persists.
                </ErrorMessage>
              </Div>
            );
          } else if (!bankAccount || bankAccount.pending_confirmation) {
            return LinkOrConfirmBank(bankAccount);
          } else if (readyForTransfer) {
            return InitiateTransfer(
              bankAccount,
              disbursing,
              handleFundsTransfer
            );
          } else if (!readyForVerification) {
            return CheckCitizenship();
          } else if (!readyForTransfer && readyForVerification) {
            return CreatePersonalInfo(handleIDVerification);
          }
        })()}
      </Div>
    </StyledContainer>
  );
};

export default BankDepositDisbursementView;
