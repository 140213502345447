import { useParams } from "react-router-dom";
import { COLORS } from "../../global/colors";
import styled from "styled-components";
import Div from "../../baseComponents/Div";
import Row from "../../baseComponents/Row";
import HoorayCoin from "../../../images/HoorayCoin";
import ChillCoin from "../../../images/ChillCoin";
import useGetCurrentPrepayment from "../../../utils/useGetCurrentPrepayment";
import { GenericObject } from "../../global/ModelInterfaces";
import { cssBreakpoints } from "../../global/theme";
import { registerMonthlyPaymentPlanClick } from "../../../utils/depositUtils";

const Title = styled.h1`
  font-weight: bold;
  margin-bottom: 2rem;
`;

const PaymentPlan = styled(Div)`
  background-color: ${COLORS.navy};
  color: ${COLORS.white};
  border-radius: 1rem;
  padding: 1rem;
  cursor: pointer;
  &:hover span {
    text-decoration: underline;
  }
  ${cssBreakpoints("margin-bottom", [{ sm: "1rem" }, { md: "0" }])}
`;

const FullPayment = styled(Div)`
  background-color: ${COLORS.white};
  border: 1px solid ${COLORS.grey20};
  border-radius: 0.8rem;
  padding: 1rem;
  cursor: pointer;
  &:hover span {
    text-decoration: underline;
  }
`;

const PaymentOption = styled(Row)`
  font-weight: bold;
  margin-bottom: 1rem;
`;

const PlanDetails = styled(Row)`
  font-size: smaller;
  margin-top: 0.8rem;
`;

const LearnMore = styled(Div)`
  color: ${COLORS.primary};
  cursor: pointer;
  font-weight: bold;
  &:hover {
    text-decoration: underline;
  }
`;

const BankInfo = styled.div`
  border: 1px solid ${COLORS.primary};
  border-radius: 0.8rem;
  padding: 0.8rem;
  font-size: smaller;
  margin-top: 0.8rem;
`;

interface Props {
  bankAccount: any;
  choosePaymentPlan: () => void;
  choosePayInFull: () => void;
}

const PaymentChoice = ({
  bankAccount,
  choosePaymentPlan,
  choosePayInFull,
}: Props) => {
  const { prepaymentUUID } = useParams<GenericObject>();
  const { prepayment } = useGetCurrentPrepayment(prepaymentUUID);
  const securityDeposit = prepayment.deposits.security_deposit;

  return (
    <>
      <Title>How would you like to pay for your deposit?</Title>
      <Row>
        <Div width={{ sm: 1, lg: 1 / 2 }}>
          <PaymentPlan
            width={{ default: 1 }}
            onClick={() => {
              choosePaymentPlan();
              registerMonthlyPaymentPlanClick();
            }}
          >
            <PaymentOption>
              <Div>
                <ChillCoin />
              </Div>
            </PaymentOption>
            <span>
              <b>
                Spread out your payments <br /> with Rentable
              </b>
            </span>
            <PlanDetails>
              Rentable Payment Plan <LearnMore>Learn More</LearnMore>
            </PlanDetails>
          </PaymentPlan>
        </Div>
        <Div width={{ sm: 1, lg: 1 / 2 }}>
          <FullPayment
            width={{ default: 1 }}
            onClick={() => {
              choosePayInFull();
            }}
          >
            <PaymentOption justifyContent="space-between">
              <Div>
                <HoorayCoin />
              </Div>
              ${securityDeposit.amount}
            </PaymentOption>
            <span>
              <b>Pay in full</b>
            </span>
            {bankAccount ? (
              <BankInfo>
                <b>
                  {bankAccount.bank_name}
                  &nbsp; •••• &nbsp;
                </b>

                {bankAccount.mask}
              </BankInfo>
            ) : (
              <BankInfo>No bank account linked</BankInfo>
            )}
          </FullPayment>
        </Div>
      </Row>
    </>
  );
};

export default PaymentChoice;
