import styled from "styled-components";
import Div from "../../../components/baseComponents/Div";
import Row from "../../../components/baseComponents/Row";
import DropdownCard from "../../../components/baseComponents/DropdownCard";
import { GenericObject } from "../../../components/global/ModelInterfaces";
import {
  currencyFormatter,
  dateShortFormatter,
} from "../../../components/global/utils";
import { some } from "lodash";
import ButtonToForm from "../ButtonToForm";
import EditPaymentPlanApprovalForm from "../Forms/EditPaymentPlanApprovalForm";
import EditExistingPaymentPlanForm from "../Forms/EditExistingPaymentPlanForm";
import { useParams } from "react-router-dom";
import { nucleusUserPermissions } from "../../../enums/nucleusPermissions";
import useNucleusPermissions from "../../../utils/useNucleusPermissions";
interface Props {
  paymentPlanInfo: GenericObject;
}
const StyledText = styled.span`
  font-size: ${(props) => props.theme.font_size.headlineMD};
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;
const StyledLabel = styled.span`
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;
const PaymentPlanInfo = ({ paymentPlanInfo }: Props) => {
  const { prepaymentUUID } = useParams<GenericObject>();
  const { loadingPermissions, adminSettingsPermission } =
    useNucleusPermissions();

  return (
    <DropdownCard title="Payment Plan Info">
      <Div width={{ default: 12 / 12 }}>
        {some(paymentPlanInfo) ? (
          <>
            <Row>
              <Div width={{ md: 6 / 12, lg: 8 / 12 }}>
                <Row>
                  <StyledLabel>Number of Payments:</StyledLabel>
                  {paymentPlanInfo.number_of_payments}
                </Row>
                <Row>
                  <StyledLabel>Initial Payment:</StyledLabel>
                  {currencyFormatter.format(paymentPlanInfo.initial_payment)}
                </Row>
                <Row>
                  <StyledLabel>Monthly Payment:</StyledLabel>
                  {currencyFormatter.format(paymentPlanInfo.amount)}
                </Row>
                <Row>
                  <StyledLabel>Fee:</StyledLabel>
                  {currencyFormatter.format(paymentPlanInfo.fee)}
                </Row>
                <Row>
                  <StyledLabel>Last Scheduled Payment:</StyledLabel>
                  {dateShortFormatter(paymentPlanInfo.last_scheduled_payment)}
                </Row>
                <Row>
                  <StyledLabel>UUID:</StyledLabel>
                  {paymentPlanInfo.uuid}
                </Row>
              </Div>
              {adminSettingsPermission === nucleusUserPermissions.WRITE && (
                <Div width={{ md: 6 / 12, lg: 4 / 12 }}>
                  <Row>
                    <Div width={{ default: 1 }}>
                      <ButtonToForm
                        formTitle={"Edit Existing Payment Plan"}
                        buttonText={"Edit Payment Plan"}
                      >
                        <EditExistingPaymentPlanForm
                          prepaymentUUID={prepaymentUUID}
                        />
                      </ButtonToForm>
                    </Div>
                  </Row>
                </Div>
              )}
            </Row>
          </>
        ) : (
          <Div>
            <Row pb={{ default: 3 }}>
              <StyledText>
                This prepayment does not have a payment plan.
              </StyledText>
            </Row>
            {adminSettingsPermission === nucleusUserPermissions.WRITE && (
              <ButtonToForm
                formTitle={"Payment Plan Approval"}
                buttonText={"Approve Payment Plan"}
              >
                <EditPaymentPlanApprovalForm prepaymentUUID={prepaymentUUID} />
              </ButtonToForm>
            )}
          </Div>
        )}
      </Div>
    </DropdownCard>
  );
};

export default PaymentPlanInfo;
