import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { variant } from "styled-system";
import Div from "./Div";

interface PrefixProps {
  focused: boolean;
}
const StyledPrefixSymbol = styled(Div)<PrefixProps>`
  width: 3.5rem;
  background-color: lightgray;
  display: inline;
  height: 3.5rem;
  border-radius: 8px 0 0 8px;
  margin: 8px 0;
  padding: 0.75rem 0;
  font-size: 1.25rem;
  font-weight: 700;
  text-align: center;
  ${(props) =>
    variant({
      prop: "focused",
      variants: {
        true: {
          paddingTop: "0.65rem",
          paddingRight: "0.1rem",
          border: "2px solid " + props.theme.colors.outlineGrey,
          borderRight: 0,
        },
      },
    })}
`;

const StyledInputContainer = styled.div`
  flex-direction: row;
  display: flex;
`;

interface StyleProps {
  variant?: string;
  error?: boolean;
  prefixed?: boolean;
  heightVariant?: string;
  focused?: boolean;
}

const StyledInput = styled.input<StyleProps>`
  border-radius: ${(props) => props.theme.border_radius.SM};
  margin: 8px 0px;
  border: none;
  padding: 1rem;
  display: inline;
  width: 100%
    ${(props) =>
      variant({
        prop: "variant",
        variants: {
          light: {
            backgroundColor: props.theme.colors.grey5,
            color: props.theme.colors.grey60,
            "::placeholder": {
              color: props.theme.colors.grey60,
            },
          },
          dark: {
            backgroundColor: props.theme.colors.grey80,
            color: props.theme.colors.grey20,
            "::placeholder": {
              color: props.theme.colors.grey20,
            },
          },
        },
      })};
  ${(props) =>
    variant({
      prop: "error",
      variants: {
        true: {
          backgroundColor: props.theme.colors.backgroundWarning,
        },
      },
    })};
  ${(props) =>
    variant({
      prop: "prefixed",
      variants: {
        true: {
          borderRadius: "0 8px 8px 0",
          width: "100%",
          outline: props.focused ? "none !important" : "inherit",
          border: props.focused
            ? "2px solid " + props.theme.colors.outlineGrey
            : "inherit",
          borderLeft: props.focused ? "none" : "inherit",
        },
      },
    })};
  ${variant({
    prop: "heightVariant",
    variants: {
      default: {
        height: "3.5rem",
      },
      short: {
        height: "2.5rem",
      },
    },
  })};
`;

const StyledLabel = styled.span`
  font-weight: 700;
  font-size: 0.875rem;
`;

const StyledHelpText = styled.span`
  font-size: 0.563rem;
  color: ${(props) => props.theme.colors.grey60};
`;

interface Props {
  placeholderText?: string;
  defaultValue?: string;
  helpText?: string;
  backgroundColor?: string;
  label?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  value?: string;
  className?: string;
  error?: boolean;
  type?: string;
  prefixSymbol?: string;
  heightVariant?: string;
}

const Input = ({
  placeholderText,
  defaultValue,
  helpText,
  label,
  backgroundColor,
  onChange,
  onBlur,
  onKeyDown,
  value,
  className,
  type,
  prefixSymbol,
  error = false,
  heightVariant = "default",
}: Props) => {
  const inputRef = useRef<any>();
  const [focused, setFocused] = useState(false);
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    const handleInputScroll = (event: any) => {
      event.target.blur();
    };

    // Bind the event listener
    document.addEventListener("wheel", handleInputScroll);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("wheel", handleInputScroll);
    };
  }, []);

  return (
    <div ref={inputRef}>
      {label && <StyledLabel>{label}</StyledLabel>}
      <StyledInputContainer>
        {prefixSymbol && (
          <Div>
            <StyledPrefixSymbol focused={focused}>
              {prefixSymbol}
            </StyledPrefixSymbol>
          </Div>
        )}
        <Div width={"fill"}>
          <StyledInput
            variant={backgroundColor || "light"}
            placeholder={placeholderText}
            defaultValue={defaultValue}
            onChange={onChange}
            value={value}
            error={error}
            className={className}
            type={type}
            prefixed={!!prefixSymbol}
            focused={focused}
            onFocus={() => setFocused(true)}
            onBlur={(e) => {
              setFocused(false);
              onBlur && onBlur(e);
            }}
            onKeyDown={(e) => e.key === "Enter" && onKeyDown && onKeyDown(e)}
            heightVariant={heightVariant}
          />
        </Div>
      </StyledInputContainer>
      {helpText && <StyledHelpText>{helpText}</StyledHelpText>}
    </div>
  );
};

export default Input;
