import { useEffect, useState } from "react";
import Input from "./Input";

interface Props {
  onChange: (value: number) => void;
  error?: boolean;
  label?: string;
}

const CurrencyInput = ({ onChange, error, label }: Props) => {
  const [value, setValue] = useState<string>();
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };
  useEffect(() => {
    if (value) {
      onChange(parseFloat(value));
    } else {
      onChange(0);
    }
  }, [value]);

  return (
    <>
      <Input
        onChange={handleChange}
        value={value}
        error={error}
        label={label}
        prefixSymbol={"$"}
        type="number"
      />
    </>
  );
};

export default CurrencyInput;
