import { useState } from "react";
import PaymentModal from "../../baseComponents/PaymentModal";
import Button from "../../baseComponents/Button";
import { GenericObject } from "../../global/ModelInterfaces";

interface Props {
  text: string;
  paymentPlan: GenericObject;
  paymentType: string;
  prepaymentUUID: string;
  onPaymentSuccess: (amount: number) => void;
  disabled?: boolean;
  onClick?: () => void;
  onDisabledClick?: () => void;
  loading?: boolean;
}

const PaymentButton = ({
  text,
  paymentPlan,
  paymentType,
  prepaymentUUID,
  onPaymentSuccess,
  disabled,
  onClick,
  onDisabledClick,
  loading,
}: Props) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Button
        onClick={() => {
          if (onClick) onClick();
          setModalOpen(true);
        }}
        text={text}
        type="secondary"
        disabled={disabled}
        onDisabledClick={onDisabledClick}
        loading={loading}
      />
      <PaymentModal
        showModal={modalOpen}
        closeModal={() => setModalOpen(false)}
        prepaymentUUID={prepaymentUUID}
        paymentPlan={paymentPlan}
        paymentType={paymentType}
        onSuccess={onPaymentSuccess}
      />
    </>
  );
};

export default PaymentButton;
