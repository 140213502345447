import { useParams, Redirect, useHistory } from "react-router-dom";
import { useAppDispatch } from "../../store/hooks";
import { RootState } from "../..";
import { updateUnit, removeUnit } from "../../store/units";
import prepaymentStatuses from "../../enums/prepaymentStatuses";
import { updateUnitAddress2, deleteUnit } from "../../utils/unitUtils";
import styled from "styled-components";
import TitleHeader from "../baseComponents/TitleHeader";
import MetricsOverview from "../baseComponents/MetricsOverview";
import ListItem from "../baseComponents/ListItem";
import Menu from "../baseComponents/Menu";
import { MenuItem } from "../global/ModelInterfaces";
import ConfirmationModal from "../baseComponents/ConfirmationModal";
import UserInitialsAvatar from "../baseComponents/UserInitialsAvatar";
import Container from "../baseComponents/Container";
import Row from "../baseComponents/Row";
import Div from "../baseComponents/Div";
import BackButton from "../baseComponents/BackButton";
import Button from "../baseComponents/Button";
import PageLoader from "../PageLoader";
import Input from "../baseComponents/Input";
import CloseIcon from "../../images/CloseIcon";
import UnitIcon from "../../images/UnitIcon";
import {
  formatDateValueAsLocale,
  navigateToTenant,
  currencyFormatter,
} from "../global/utils";
import { cssBreakpoints } from "../global/theme";
import {
  Unit,
  Metric,
  newMetric,
  GenericObject,
  Building,
  Prepayment,
} from "../global/ModelInterfaces";
import { useAppSelector } from "../../store/hooks";
import { useState, useEffect } from "react";

const StyledCloseIcon = styled.a`
  justify-content: end;
`;

const StyledEditSection = styled.div`
  border: 1px solid black;
  border-radius: 0.8rem;
  padding: 24px;
  margin-bottom: 24px;
  margin-top: 16px;
`;

const StyledButtonRow = styled(Row)`
  margin: 16px 0;
  padding: 0 16px;
`;

const StyledHeader = styled(Row)`
  margin-bottom: 0.5rem;
  ${cssBreakpoints("margin-top", [{ sm: "2rem" }, { md: "2rem" }, { lg: "0" }])}
`;

const StyledMetrics = styled(Row)`
  ${cssBreakpoints("padding", [{ md: " 0 4rem" }])}
`;

const UnitView = () => {
  const [editUnitInfo, setEditUnitInfo] = useState(false);

  const dispatch = useAppDispatch();
  const history = useHistory();
  const { unitUUID } = useParams<GenericObject>();
  const unitsSlice: { loading: Boolean; objects: Unit[] } = useAppSelector(
    (state: RootState) => state.units
  );

  const loadingUnits = unitsSlice.loading;
  const buildingSlice: { loading: Boolean; objects: Building[] } =
    useAppSelector((state: RootState) => state.buildings);

  const selectedUnit = unitsSlice.objects.find(
    (unit: Unit) => unit.uuid === unitUUID
  );

  const selectedBuilding = buildingSlice.objects.find(
    (building: Building) => building.id === selectedUnit?.building_id
  );

  const loadingBuildings = buildingSlice.loading;

  const [unit, setUnit] = useState(
    selectedUnit?.street_address.split(",")[1] ?? ""
  );
  const [errors, setErrors] = useState<GenericObject>({});
  const [updateError, setUpdateError] = useState("");

  // Confirmation modal
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    setUnit(selectedUnit?.street_address.split(",")[1] ?? "");
  }, [selectedUnit]);

  const prepaymentSlice: { loading: Boolean; objects: Prepayment[] } =
    useAppSelector((state: RootState) => state.prepayments);
  const loadingPrepayments = prepaymentSlice.loading;
  const associatedPrepayments: Prepayment[] = prepaymentSlice.objects.filter(
    (prepayment: Prepayment) =>
      prepayment.unit_uuid === unitUUID &&
      prepayment.status_id !== prepaymentStatuses.CLOSED
  );

  const totalDepositAmount = associatedPrepayments.reduce((a, b) => {
    return a + parseFloat(b.amount);
  }, 0);

  const validateForm = () => {
    let tmpErrors = errors;
    if (!unit) {
      tmpErrors = { ...tmpErrors, name: true };
    }
    setErrors(tmpErrors);
    return !Object.keys(tmpErrors).some((key: string) => !!tmpErrors[key]);
  };

  const clearError = (key: string) => {
    let tmpErrors = errors;
    tmpErrors[key] = false;
    setErrors(tmpErrors);
  };

  const handleUpdate = () => {
    if (validateForm()) {
      const uuid = selectedUnit?.uuid;
      updateUnitAddress2(unit, uuid)
        .then((updatedUnit) => {
          dispatch(updateUnit(updatedUnit));
          setEditUnitInfo(false);
        })
        .catch(() => {
          setUpdateError(
            "A problem occurred when updating the building info. Please refresh and try again, or if this issue persists please contact support@rentable.com."
          );
        });
    }
  };

  if (loadingBuildings || loadingUnits || loadingPrepayments) {
    return (
      <Div>
        <PageLoader />
      </Div>
    );
  }

  if (!selectedUnit || !selectedBuilding) {
    return <Redirect to={"/forbidden"} />;
  }

  // ————— Handle delete unit —————
  // Building menu items and handlers
  const renderMenuItems = (buildingUUID: string) => {
    let menuItems: MenuItem[] = [];
    // Edit info
    menuItems.push({
      label: "Edit Unit Info",
      function: () => setEditUnitInfo(true),
    });

    // Show menu option only if the unit could be deleted
    if (associatedPrepayments.length == 0) {
      menuItems.push({
        label: "Delete Unit",
        function: () => setShowConfirmation(true),
      });
    }
    return menuItems;
  };

  const onDeleteUnit = (selectedUnit_uuid: { uuid: string }) => {
    dispatch(removeUnit(selectedUnit_uuid.uuid));
    setShowConfirmation(false);
    history.push(`/dashboard/building/${selectedBuilding.uuid}`);
  };

  const onFailDeleteUnit = (error_message: string) => {
    setUpdateError(
      error_message ||
        "Unit could not be deleted. Please, contact support@rentable.com."
    );
  };

  const deleteUnitHandler = () => {
    deleteUnit(selectedUnit.uuid, onDeleteUnit, onFailDeleteUnit);
  };

  const menuItems = renderMenuItems(selectedUnit.uuid);
  const unitMetrics: Metric[] = [
    newMetric(
      "Total Deposit Amount",
      currencyFormatter.format(totalDepositAmount)
    ),
    newMetric("Number of Tenants", String(associatedPrepayments.length)),
  ];

  return (
    <Container>
      <BackButton
        returnUrl={`/dashboard/building/${selectedBuilding.uuid}`}
        text={`Go to ${selectedBuilding.name}`}
      />
      <Row justifyContent="center">
        <Div width={{ sm: 1, md: 1, lg: 9 / 12 }}>
          <StyledHeader>
            <Div width={{ sm: 1, lg: 9 / 12 }}>
              <TitleHeader
                title={selectedUnit.street_address}
                subtitle={`${selectedBuilding?.name} • ${selectedUnit.city}, ${selectedUnit.state}, ${selectedUnit.zip_code}`}
                Icon={UnitIcon}
                iconHeight={"42"}
                iconWidth={"42"}
              />
            </Div>
            <Div width={{ sm: 1, lg: 3 / 12 }} alignItems="end">
              <Row flexWrap="nowrap">
                <Button
                  text="Add Tenant"
                  type="primary"
                  onClick={() => {
                    history.push(`/dashboard/invite/${selectedUnit.uuid}`);
                  }}
                />
                {menuItems.length > 0 && (
                  <Menu menuItems={menuItems} style="ellipsis" />
                )}
              </Row>
            </Div>
            <ConfirmationModal
              confirmationOpen={showConfirmation}
              onClose={() => setShowConfirmation(false)}
              onConfirm={deleteUnitHandler}
              errorMessage={updateError}
              message={`Are you sure you would like to delete ${selectedUnit.street_address}?`}
            />
          </StyledHeader>
          <StyledMetrics>
            <MetricsOverview metrics={unitMetrics} />
          </StyledMetrics>
          {editUnitInfo && (
            <StyledEditSection>
              <Row justifyContent="space-between">
                <h2>Edit Unit</h2>
                <StyledCloseIcon
                  title="Close Edit Modal"
                  onClick={() => {
                    setEditUnitInfo(false);
                  }}
                >
                  <CloseIcon />
                </StyledCloseIcon>
              </Row>
              <Row justifyContent="center">
                <Div width={{ default: 1, sm: 1, md: 1 / 3, lg: 1 / 3 }}>
                  <Input
                    label="Unit"
                    value={unit}
                    onChange={(e) => {
                      clearError("unit");
                      setUnit(e.target.value);
                    }}
                    backgroundColor="light"
                    error={errors.unit}
                  />
                </Div>
              </Row>
              <StyledButtonRow justifyContent="center">
                <Div width={{ default: 1, sm: 1, md: 1 / 2, lg: 1 / 2 }}>
                  <Button type="primary" onClick={handleUpdate}>
                    Update Unit
                  </Button>
                </Div>
              </StyledButtonRow>
            </StyledEditSection>
          )}

          {associatedPrepayments.map((prepayment: any) => {
            let leaseEndDate = "";
            if (prepayment.end_date) {
              leaseEndDate = formatDateValueAsLocale(prepayment.end_date);
            } else {
              leaseEndDate = "Month to month";
            }
            const prepaymentMetrics = [
              [
                "leaseStartDate",
                formatDateValueAsLocale(prepayment.start_date),
              ],
              ["leaseEndDate", leaseEndDate],
              ["leaseAmount", currencyFormatter.format(prepayment.amount)],
            ];

            return (
              <Row>
                <Div width={{ default: 1 }}>
                  <ListItem
                    title={prepayment.tenant_name}
                    details={[
                      selectedUnit.street_address,
                      prepayment.tenant_email,
                    ]}
                    Icon={() => (
                      <UserInitialsAvatar name={prepayment.tenant_name} />
                    )}
                    metrics={prepaymentMetrics}
                    onClick={() => navigateToTenant(prepayment.uuid, history)}
                  />
                </Div>
              </Row>
            );
          })}
        </Div>
      </Row>
    </Container>
  );
};

export default UnitView;
