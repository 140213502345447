import styled from "styled-components";
import Div from "../../../components/baseComponents/Div";
import Row from "../../../components/baseComponents/Row";
import DropdownCard from "../../../components/baseComponents/DropdownCard";
import { GenericObject } from "../../../components/global/ModelInterfaces";
import {
  currencyFormatter,
  dateShortFormatter,
} from "../../../components/global/utils";
interface Props {
  additionalFundsInfo: GenericObject;
}
const StyledText = styled.span`
  font-size: ${(props) => props.theme.font_size.headlineMD};
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;
const StyledSubTitle = styled(Row)`
  font-size: ${(props) => props.theme.font_size.headlineSM};
  font-weight: ${(props) => props.theme.font_weight.bolder};
`;
const StyledLabel = styled.span`
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;

const StyledRequestBox = styled(Div)`
  border: ${(props) => props.theme.borders.primarySM};
  border-radius: ${(props) => props.theme.border_radius.SM};
`;

const StyledRow = styled(Row)`
  font-size: ${(props) => props.theme.font_size.bodyMD};
`;

const AdditionalFundsInfo = ({ additionalFundsInfo }: Props) => {
  const additionalFundsRequests = additionalFundsInfo.requests;
  const additionalFundsPayments = additionalFundsInfo.payments;
  return (
    <DropdownCard title="Additional Funds Info">
      <Div width={{ default: 12 / 12 }}>
        {additionalFundsRequests && (
          <>
            <StyledSubTitle>Additional Funds Requests:</StyledSubTitle>
            <Row>
              {additionalFundsRequests.map((request: GenericObject) => {
                return (
                  <Div width={{ default: 1 / 3 }}>
                    <StyledRequestBox mt={{ default: 2 }} p={{ default: 2 }}>
                      <StyledRow>
                        <StyledLabel>UUID:</StyledLabel>
                        {request.uuid}
                      </StyledRow>
                      <StyledRow>
                        <StyledLabel>Amount:</StyledLabel>
                        {currencyFormatter.format(request.amount)}
                      </StyledRow>
                      <StyledRow>
                        <StyledLabel>Requested On:</StyledLabel>
                        {dateShortFormatter(request.requested_at)}
                      </StyledRow>
                      <StyledRow>
                        <StyledLabel>Funded On:</StyledLabel>
                        {request.funded_at
                          ? dateShortFormatter(request.funded_at)
                          : "N/A"}
                      </StyledRow>
                    </StyledRequestBox>
                  </Div>
                );
              })}
            </Row>
          </>
        )}
        {additionalFundsPayments && (
          <>
            <StyledSubTitle mt={{ default: 3 }}>
              Additional Funds Payments:
            </StyledSubTitle>
            <Row>
              {additionalFundsPayments.map((payment: GenericObject) => {
                return (
                  <Div width={{ default: 1 / 3 }}>
                    <StyledRequestBox mt={{ default: 2 }} p={{ default: 2 }}>
                      <StyledRow>
                        <StyledLabel>UUID:</StyledLabel>
                        {payment.uuid}
                      </StyledRow>
                      <StyledRow>
                        <StyledLabel>Amount:</StyledLabel>
                        {currencyFormatter.format(payment.amount)}
                      </StyledRow>
                      <StyledRow>
                        <StyledLabel>Fee Amount:</StyledLabel>
                        {currencyFormatter.format(payment.fee_amount)}
                      </StyledRow>
                      <StyledRow>
                        <StyledLabel>Transaction Date:</StyledLabel>
                        {dateShortFormatter(payment.transaction_date)}
                      </StyledRow>
                      <StyledRow>
                        <StyledLabel>
                          Associated Dwolla Transaction ID:
                        </StyledLabel>
                        {payment.dwolla_transaction_id ?? "N/A"}
                      </StyledRow>
                      <StyledRow>
                        <StyledLabel>Funded By:</StyledLabel>
                        {payment.user_email}
                      </StyledRow>
                    </StyledRequestBox>
                  </Div>
                );
              })}
            </Row>
          </>
        )}
        {!additionalFundsRequests && (
          <Row>
            <StyledText>
              This prepayment has no additional funds requests.
            </StyledText>
          </Row>
        )}
      </Div>
    </DropdownCard>
  );
};

export default AdditionalFundsInfo;
