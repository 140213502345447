import { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { RootState } from "../..";
import { useParams, useHistory } from "react-router-dom";
import styled from "styled-components";
import { updatePrepayment } from "../../store/prepayments";
import Div from "../baseComponents/Div";
import Row from "../baseComponents/Row";
import PageLoader from "../PageLoader";
import BackButton from "../baseComponents/BackButton";
import Button from "../baseComponents/Button";
import Container from "../baseComponents/Container";
import CurrencyInput from "../baseComponents/CurrencyInput";
import ErrorMessage from "../baseComponents/ErrorMessage";
import ConfirmationModal from "../baseComponents/ConfirmationModal";
import { cssBreakpoints } from "../global/theme";
import { currencyFormatter } from "../global/utils";
import { GenericObject, Prepayment } from "../global/ModelInterfaces";
import { createPartialDepositRefund } from "../../utils/depositUtils";
import prepaymentStatuses from "../../enums/prepaymentStatuses";

const StyledForm = styled(Row)`
  ${cssBreakpoints("margin-top", [{ sm: "2rem" }])}
`;

const StyledButton = styled(Button)`
  ${cssBreakpoints("margin-top", [{ sm: "2rem" }])}
`;
const StyledTitle = styled(Row)`
  ${cssBreakpoints("margin-bottom", [{ sm: "2rem" }])}
  font-weight: ${(props) => props.theme.font_weight.semibold};
  font-size: ${(props) => props.theme.font_size.headlineMD};
`;

const StyledSummaryBox = styled(Div)`
  border: ${(props) => `1px solid ${props.theme.colors.black}`};
  border-radius: 0.5rem;
  padding: 2rem;
  ${cssBreakpoints("margin-bottom", [{ sm: "2rem" }])}
`;

const StyledLabel = styled(Div)`
  font-weight: ${(props) => props.theme.font_weight.semibold};

  font-size: ${(props) => props.theme.font_size.headlineSM};
`;

const StyledTotal = styled(Div)`
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;

const CreateRefundView = () => {
  const { prepaymentUUID } = useParams<GenericObject>();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [securityDepositRefundAmount, setSecurityDepositRefundAmount] =
    useState<number>(0);
  const [lastMonthsRentRefundAmount, setLastMonthsRentRefundAmount] =
    useState<number>(0);
  const [validRefundAmounts, setValidRefundAmounts] = useState<boolean>(false);
  const [success, setSuccess] = useState(false);
  const [securityDepositRefundSuccess, setSecurityDepositRefundSuccess] =
    useState<boolean>(false);
  const [lastMonthsRentRefundSuccess, setLastMonthsRefundSuccess] =
    useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const prepaymentSlice: { loading: boolean; objects: Prepayment[] } =
    useAppSelector((state: RootState) => state.prepayments);

  const loadingPrepayments = prepaymentSlice.loading;
  const selectedPrepayment = prepaymentSlice.objects.find(
    (prepayment: Prepayment) => prepayment.uuid === prepaymentUUID
  );
  const handleRefund = () => {
    setLoading(true);
    if (selectedPrepayment) {
      const successCallBack = (
        updatedPrepayment: GenericObject,
        refundResults: GenericObject
      ) => {
        dispatch(updatePrepayment(updatedPrepayment));
        setSuccess(true);
        setSecurityDepositRefundSuccess(refundResults.security_deposit);
        setLastMonthsRefundSuccess(refundResults.last_months_rent);
        setLoading(false);
      };
      const errorCallback = (errorMessage: string) => {
        setError(`There was an error creating the refund: ${errorMessage}`);
        setLoading(false);
      };
      createPartialDepositRefund(
        selectedPrepayment.uuid,
        securityDepositRefundAmount,
        lastMonthsRentRefundAmount,
        successCallBack,
        errorCallback
      );
    }
  };

  const returnToPrepayment = () => {
    if (selectedPrepayment) {
      history.push(`/dashboard/tenant/${selectedPrepayment.uuid}`);
    }
  };

  const calculateTotalAmount = (
    depositAmount: number,
    refundAmount: number
  ) => {
    return currencyFormatter.format(depositAmount - refundAmount);
  };

  const validateRefundAmounts = () => {
    const securityDeposit = selectedPrepayment?.deposits.security_deposit;
    const lastMonthsRent = selectedPrepayment?.deposits.last_months_rent;

    const securityDepositAmountValid = securityDeposit
      ? securityDepositRefundAmount <=
        selectedPrepayment?.deposits.security_deposit.amount
      : true;

    const lastMonthsRentAmountValid = lastMonthsRent
      ? lastMonthsRentRefundAmount <=
        selectedPrepayment?.deposits.last_months_rent.amount
      : true;

    return (
      !!(securityDepositRefundAmount || lastMonthsRentRefundAmount) &&
      securityDepositAmountValid &&
      lastMonthsRentAmountValid
    );
  };

  const refundConfirmationMessage = () => {
    let confirmationMessage = "";
    if (securityDepositRefundAmount) {
      confirmationMessage += `Submit a partial refund for the Security Deposit in the amount of ${currencyFormatter.format(
        securityDepositRefundAmount
      )}. \n New total for Security Deposit will be ${calculateTotalAmount(
        parseFloat(selectedPrepayment?.deposits.security_deposit.amount),
        securityDepositRefundAmount
      )}. \n\n`;
    }
    if (lastMonthsRentRefundAmount) {
      confirmationMessage += ` Submit a partial refund for the Last Month's Rent in the amount of ${currencyFormatter.format(
        lastMonthsRentRefundAmount
      )}. \n New total for Last Month's Rent will be ${calculateTotalAmount(
        parseFloat(selectedPrepayment?.deposits.last_months_rent.amount),
        lastMonthsRentRefundAmount
      )}.`;
    }
    return confirmationMessage;
  };

  useEffect(() => {
    setValidRefundAmounts(validateRefundAmounts());
  }, [securityDepositRefundAmount, lastMonthsRentRefundAmount]);

  if (loadingPrepayments) {
    return (
      <Div>
        <PageLoader />
      </Div>
    );
  }
  if (!selectedPrepayment) {
    history.push("/forbidden");
  } else if (!selectedPrepayment.deposits) {
    returnToPrepayment();
  } else if (success) {
    return (
      <Container>
        <BackButton
          text={"Back to Deposit"}
          returnUrl={`/dashboard/tenant/${selectedPrepayment.uuid}`}
        />
        <StyledForm justifyContent="center">
          <StyledLabel width={{ default: 1 }} alignItems="center">
            {"Successfully Completed Refund(s):"}
            <br />
            {securityDepositRefundSuccess &&
              securityDepositRefundAmount > 0 && (
                <Div>
                  Refunded a portion of the Security Deposit in the amount of{" "}
                  {currencyFormatter.format(securityDepositRefundAmount)}.
                </Div>
              )}
            {!securityDepositRefundSuccess &&
              securityDepositRefundAmount > 0 && (
                <ErrorMessage>
                  There was an error creating a refund for the security deposit.
                  Please try agin or contact suppor@rentable.com if you have any
                  questions.
                </ErrorMessage>
              )}
            {lastMonthsRentRefundSuccess && lastMonthsRentRefundAmount > 0 && (
              <Div>
                Refunded a portion of the Last Month's Rent in the amount of{" "}
                {currencyFormatter.format(lastMonthsRentRefundAmount)}.
              </Div>
            )}
            {!lastMonthsRentRefundSuccess && lastMonthsRentRefundAmount > 0 && (
              <ErrorMessage>
                There was an error creating a refund for the last month's rent.
                Please try agin or contact suppor@rentable.com if you have any
                questions.
              </ErrorMessage>
            )}
          </StyledLabel>
          <Div width={{ default: 1, lg: 1 / 3 }} alignItems="center">
            <StyledButton
              type="primary"
              text={`Return to Deposit`}
              onClick={returnToPrepayment}
            />
          </Div>
        </StyledForm>
      </Container>
    );
  } else {
    return (
      <>
        <Container>
          <BackButton
            text={"Back to Deposit"}
            returnUrl={`/dashboard/tenant/${selectedPrepayment.uuid}`}
          />
          <Div width={{ default: 1 }}>
            <StyledForm justifyContent="center">
              <StyledSummaryBox width={{ default: 8 / 12 }}>
                <StyledTitle justifyContent="center">
                  Create Partial Refund
                </StyledTitle>
                <Row justifyContent="center">
                  {selectedPrepayment.deposits.security_deposit &&
                    selectedPrepayment.deposits.security_deposit.status_id ===
                      prepaymentStatuses.ACTIVE && (
                      <Div width={{ default: 1, lg: 1 / 2 }}>
                        <StyledLabel width={{ default: 1 }}>
                          Security Deposit
                        </StyledLabel>
                        <Div>
                          Current Amount:{" "}
                          {currencyFormatter.format(
                            selectedPrepayment.deposits.security_deposit.amount
                          )}
                        </Div>
                        <Div width={{ default: 1 }}>
                          <Div>Amount to refund:</Div>
                          <Div width={{ default: 9 / 12 }}>
                            <CurrencyInput
                              onChange={setSecurityDepositRefundAmount}
                            />
                          </Div>
                        </Div>
                        <StyledTotal width={{ default: 1 }}>
                          {securityDepositRefundAmount >=
                          selectedPrepayment.deposits.security_deposit
                            .amount ? (
                            <>
                              The refund amount must not exceed the current
                              amount. If you would like to close this Security
                              Deposit use the 'Close Deposit' option in the
                              previous menu.
                            </>
                          ) : (
                            <>
                              Security Deposit Amount after refund:{" "}
                              {calculateTotalAmount(
                                selectedPrepayment.deposits.security_deposit
                                  .amount,
                                securityDepositRefundAmount
                              )}
                            </>
                          )}
                        </StyledTotal>
                      </Div>
                    )}
                  {selectedPrepayment.deposits.last_months_rent &&
                    selectedPrepayment.deposits.last_months_rent.status_id ===
                      prepaymentStatuses.ACTIVE && (
                      <Div width={{ default: 1, lg: 1 / 2 }}>
                        <StyledLabel width={{ default: 1 }}>
                          Last Month's Rent
                        </StyledLabel>
                        <Div>
                          Current Amount:{" "}
                          {currencyFormatter.format(
                            selectedPrepayment.deposits.last_months_rent.amount
                          )}
                        </Div>
                        <Div width={{ default: 1 }}>
                          <Div>Amount to refund:</Div>
                          <Div width={{ default: 9 / 12 }}>
                            <CurrencyInput
                              onChange={setLastMonthsRentRefundAmount}
                            />
                          </Div>
                        </Div>
                        <StyledTotal width={{ default: 1 }}>
                          {lastMonthsRentRefundAmount >=
                          selectedPrepayment.deposits.last_months_rent
                            .amount ? (
                            <>
                              The refund amount must not exceed the current last
                              month's rent amount. If you would like to refund
                              all of this last month's rent please reach out to
                              support@rentable.com
                            </>
                          ) : (
                            <>
                              Last Month's Rent Amount after refund:{" "}
                              {calculateTotalAmount(
                                selectedPrepayment.deposits.last_months_rent
                                  .amount,
                                lastMonthsRentRefundAmount
                              )}
                            </>
                          )}
                        </StyledTotal>
                      </Div>
                    )}
                </Row>
                <Row justifyContent="center">
                  <Div width={{ default: 1, lg: 6 / 12 }}>
                    <StyledButton
                      disabled={!validRefundAmounts}
                      type="primary"
                      text={`Confirm Refund`}
                      onClick={() => setConfirmationOpen(true)}
                      loading={loading}
                    />
                  </Div>
                </Row>
              </StyledSummaryBox>
              {error && (
                <Row justifyContent="center">
                  <ErrorMessage>{error}</ErrorMessage>
                </Row>
              )}
            </StyledForm>
          </Div>
        </Container>
        <ConfirmationModal
          confirmationOpen={confirmationOpen}
          onConfirm={handleRefund}
          onClose={() => setConfirmationOpen(false)}
          buttonText="Submit Refund"
          message={refundConfirmationMessage()}
        />
      </>
    );
  }
  // This return is for TS
  return <></>;
};

export default CreateRefundView;
