import { useHistory } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import {
  updateBuildingLocal,
  deleteBuildingLocal,
} from "../../store/buildings";
import { updateUnit } from "../../store/units";
import prepaymentStatuses from "../../enums/prepaymentStatuses";
import {
  updateBuilding,
  deleteBuilding,
  deleteUnit,
} from "../../utils/unitUtils";
import { removeUnitsByBuildingId } from "../../store/units";
import { useParams, Redirect } from "react-router-dom";
import { currencyFormatter } from "../global/utils";
import { cssBreakpoints } from "../global/theme";

import styled from "styled-components";
import Container from "../baseComponents/Container";
import Row from "../baseComponents/Row";
import Div from "../baseComponents/Div";
import { RootState } from "../..";
import UnitIcon from "../../images/UnitIcon";
import TitleHeader from "../baseComponents/TitleHeader";
import ListItem from "../baseComponents/ListItem";
import BackButton from "../baseComponents/BackButton";
import Button from "../baseComponents/Button";
import Input from "../baseComponents/Input";
import PageLoader from "../PageLoader";
import ErrorView from "../baseComponents/ErrorView";
import BuildingIcon from "../../images/BuildingIcon";
import CloseIcon from "../../images/CloseIcon";
import ConfirmationModal from "../baseComponents/ConfirmationModal";
import Menu from "../baseComponents/Menu";
import {
  MenuItem,
  Building,
  Prepayment,
  Unit,
  GenericObject,
} from "../global/ModelInterfaces";
import { useEffect, useState } from "react";

const StyledHeader = styled(Row)`
  margin-bottom: 0.5rem;
  ${cssBreakpoints("margin-top", [{ sm: "2rem" }, { md: "2rem" }, { lg: "0" }])}
`;

const StyledCloseIcon = styled.a`
  justify-content: end;
`;

const StyledEditSection = styled.div`
  border: 1px solid black;
  border-radius: 0.8rem;
  padding: 24px;
  margin-bottom: 24px;
`;

const StyledButtonRow = styled(Row)`
  margin: 16px 0;
  padding: 0 16px;
`;

const BuildingDetailsView = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { buildingUUID } = useParams<GenericObject>();
  const buildingSlice: { loading: Boolean; objects: Building[] } =
    useAppSelector((state: RootState) => state.buildings);

  const loadingBuildings = buildingSlice.loading;
  const selectedBuilding = buildingSlice.objects.find(
    (building: Building) => building.uuid === buildingUUID
  );

  const prepaymentSlice: { loading: Boolean; objects: Prepayment[] } =
    useAppSelector((state: RootState) => state.prepayments);

  const loadingPrepayments = prepaymentSlice.loading;
  const prepayments = prepaymentSlice.objects;

  const unitSlice: { loading: Boolean; objects: Unit[] } = useAppSelector(
    (state: RootState) => state.units
  );

  const loadingUnits = unitSlice.loading;

  const buildingUnits = unitSlice.objects.filter(
    (unit: Unit) => selectedBuilding && unit.building_id === selectedBuilding.id
  );

  const [editBuildingInfo, setEditBuildingInfo] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [name, setName] = useState(selectedBuilding?.name ?? "");
  const [address1, setAddress1] = useState(selectedBuilding?.address_1 ?? "");
  const [city, setCity] = useState(selectedBuilding?.city ?? "");
  const [state, setState] = useState(selectedBuilding?.state ?? "");
  const [zipcode, setZipCode] = useState(selectedBuilding?.zip_code ?? "");
  const [errors, setErrors] = useState<GenericObject>({});
  const [updateError, setUpdateError] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false); // Building delete modal

  useEffect(() => {
    setName(selectedBuilding?.name ?? "");
    setAddress1(selectedBuilding?.address_1 ?? "");
    setCity(selectedBuilding?.city ?? "");
    setState(selectedBuilding?.state ?? "");
    setZipCode(selectedBuilding?.zip_code ?? "");
  }, [selectedBuilding]);

  const addBuildingMetrics = (buildingUnits: Unit[]) => {
    buildingUnits.forEach((unit, index) => {
      let prepaymentArray: Array<number> = [];
      prepayments.forEach((prepayment) => {
        if (
          unit.uuid === prepayment.unit_uuid &&
          prepayment.status_id !== prepaymentStatuses.CLOSED
        ) {
          prepaymentArray.push(parseFloat(prepayment.amount));
        }
      });
      const numberOfTenants = prepaymentArray.length;
      const depositTotal = prepaymentArray.reduce((a, b) => a + b, 0);

      const metrics = [
        ["depositTotal", currencyFormatter.format(depositTotal)],
        ["tenants", numberOfTenants],
      ];
      buildingUnits[index].metrics = metrics;
    });
    return buildingUnits;
  };

  const buildingUnitsMetrics: Unit[] = addBuildingMetrics(buildingUnits);

  const navigateToUnit = (unitUUID: string) => {
    history.push(`/dashboard/unit/${unitUUID}`);
  };

  const validateForm = () => {
    let tmpErrors = errors;
    if (!name) {
      tmpErrors = { ...tmpErrors, name: true };
    }
    if (!address1) {
      tmpErrors = { ...tmpErrors, address1: true };
    }
    if (!city) {
      tmpErrors = { ...tmpErrors, city: true };
    }
    if (!state) {
      tmpErrors = { ...tmpErrors, state: true };
    }
    if (!zipcode) {
      tmpErrors = { ...tmpErrors, zip: true };
    }
    setErrors(tmpErrors);
    return !Object.keys(tmpErrors).some((key: string) => !!tmpErrors[key]);
  };

  const clearError = (key: string) => {
    let tmpErrors = errors;
    tmpErrors[key] = false;
    setErrors(tmpErrors);
  };

  // ——— Building/Units delete behavior ——

  // Show delete building confirmation modal
  const handleBuildingDeleteClick = () => {
    setShowConfirmation(true);
  };

  // Hide delete building confirmation modal
  const handleCloseConfirmModal = () => {
    setShowConfirmation(false);
  };

  const buildingWithoutPrepayments = () => {
    // Check if unit is in the prepayments list
    return !buildingUnits.some((unit: Unit) => {
      return prepayments.some((prepayment: Prepayment) => {
        return prepayment.unit_uuid == unit.uuid;
      });
    });
  };

  // Building menu items and adding their handlers
  const renderMenuItems = () => {
    let menuItems: MenuItem[] = [];
    // Edit building menu option
    menuItems.push({
      label: "Edit Building Info",
      function: () => {
        setEditBuildingInfo(true);
      },
    });

    if (buildingWithoutPrepayments()) {
      menuItems.push({
        label: "Delete Building",
        function: handleBuildingDeleteClick,
      });
    }
    return menuItems;
  };

  const onDeleteBuilding = (responseData: string) => {
    dispatch(removeUnitsByBuildingId(selectedBuilding?.id));
    dispatch(deleteBuildingLocal(responseData));
    setShowConfirmation(false);
    history.push(`/dashboard/buildings`);
  };

  const onFailDeleteBuilding = (errorText: string) => {
    setErrorMessage(
      errorText ||
        "Building could not be deleted. Please, contact support@rentable.com."
    );
  };

  // Collect all menu items available in a array
  const menuItems = renderMenuItems();

  // —— Handle delete Building ———
  const deleteBuildingHandler = async (uuid: string = buildingUUID) => {
    deleteBuilding(uuid, onDeleteBuilding, onFailDeleteBuilding);
  };

  // ———— Update building details ———
  const handleUpdate = () => {
    if (validateForm()) {
      const uuid = selectedBuilding?.uuid;
      updateBuilding(name, address1, city, state, zipcode, uuid)
        .then((updatedBuilding) => {
          dispatch(updateBuildingLocal(updatedBuilding.building));
          updatedBuilding.units.forEach((unit: Unit) => {
            dispatch(updateUnit(unit));
          });
          setEditBuildingInfo(false);
        })
        .catch(() => {
          setUpdateError(
            "A problem occurred when updating the building info. Please refresh and try again, or if this issue persists please contact support@rentable.com."
          );
        });
    }
  };

  if (updateError.length > 0) {
    return (
      <Div>
        <ErrorView errorMessage={updateError} />
      </Div>
    );
  }

  if (loadingBuildings || loadingUnits || loadingPrepayments) {
    return (
      <Div>
        <PageLoader />
      </Div>
    );
  }

  if (!selectedBuilding) {
    return <Redirect to="/forbidden" />;
  }

  return (
    <Container>
      <BackButton
        returnUrl="/dashboard/buildings"
        text="Go to Buildings List"
      />
      <Row justifyContent="center">
        <Div width={{ sm: 1, md: 1, lg: 9 / 12 }}>
          <StyledHeader>
            <Div width={{ sm: 1, md: 1, lg: 9 / 12 }}>
              <TitleHeader
                title={selectedBuilding.name || selectedBuilding.address_1}
                subtitle={`${selectedBuilding?.address_1} • ${buildingUnits.length} Units`}
                Icon={BuildingIcon}
                iconHeight="42"
                iconWidth="42"
              />
            </Div>
            <Div width={{ sm: 1, lg: 3 / 12 }}>
              <Row flexWrap="nowrap">
                <Button
                  text="Add Unit"
                  type="primary"
                  onClick={() => {
                    history.push(
                      `/dashboard/unit/create/${selectedBuilding.uuid}`
                    );
                  }}
                />
                {menuItems.length > 0 && (
                  <Menu menuItems={menuItems} style="ellipsis" />
                )}
              </Row>
            </Div>
          </StyledHeader>

          {/* Delete BUILDING modal*/}
          <ConfirmationModal
            confirmationOpen={showConfirmation}
            onClose={handleCloseConfirmModal}
            onConfirm={deleteBuildingHandler}
            errorMessage={errorMessage}
            message={`Are you sure you would like to delete ${selectedBuilding.name}?`}
          />

          {editBuildingInfo && (
            <StyledEditSection>
              <Row justifyContent="space-between">
                <h2>Edit Building Info</h2>
                <StyledCloseIcon
                  title="Close Edit Modal"
                  onClick={() => {
                    setEditBuildingInfo(false);
                  }}
                >
                  <CloseIcon />
                </StyledCloseIcon>
              </Row>
              <Row>
                <Div width={{ default: 1, sm: 1, md: 1, lg: 1 }}>
                  <Input
                    label="Name"
                    value={name}
                    onChange={(e) => {
                      clearError("name");
                      setName(e.target.value);
                    }}
                    backgroundColor="light"
                    error={errors.name}
                  />
                </Div>
              </Row>
              <Row justifyContent="space-between">
                <Div width={{ default: 1, sm: 1, md: 1, lg: 1 }}>
                  <Input
                    label="Address"
                    value={address1}
                    onChange={(e) => {
                      clearError("address1");
                      setAddress1(e.target.value);
                    }}
                    backgroundColor="light"
                    error={errors.address1}
                  />
                </Div>
              </Row>
              <Row justifyContent="space-between">
                <Div width={{ default: 1, sm: 1, md: 4 / 12, lg: 4 / 12 }}>
                  <Input
                    label="City"
                    value={city}
                    onChange={(e) => {
                      clearError("city");
                      setCity(e.target.value);
                    }}
                    backgroundColor="light"
                    error={errors.city}
                  />
                </Div>
                <Div width={{ default: 1, sm: 1, md: 4 / 12, lg: 4 / 12 }}>
                  <Input
                    label="State"
                    value={state}
                    onChange={(e) => {
                      clearError("state");
                      setState(e.target.value);
                    }}
                    backgroundColor="light"
                    error={errors.state}
                  />
                </Div>
                <Div width={{ default: 1, sm: 1, md: 4 / 12, lg: 4 / 12 }}>
                  <Input
                    label="Zip"
                    value={zipcode}
                    onChange={(e) => {
                      clearError("zip");
                      setZipCode(e.target.value);
                    }}
                    backgroundColor="light"
                    error={errors.zip}
                  />
                </Div>
              </Row>
              <StyledButtonRow justifyContent="center">
                <Div width={{ default: 1, sm: 1, md: 6 / 12, lg: 6 / 12 }}>
                  <Button type="primary" onClick={handleUpdate}>
                    Update Building
                  </Button>
                </Div>
              </StyledButtonRow>
            </StyledEditSection>
          )}
          <Row justifyContent="center">
            <Div width={{ default: 1 }}>
              {buildingUnitsMetrics.map((unit: Unit) => {
                return (
                  <ListItem
                    key={unit.uuid}
                    Icon={UnitIcon}
                    iconHeight="42"
                    iconWidth="42"
                    title={unit.name}
                    details={[unit.street_address]}
                    metrics={unit.metrics}
                    onClick={() => {
                      navigateToUnit(unit.uuid);
                    }}
                  />
                );
              })}
            </Div>
          </Row>
        </Div>
      </Row>
    </Container>
  );
};

export default BuildingDetailsView;
