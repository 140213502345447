import { useState } from "react";
import styled from "styled-components";
import Button from "../../../../../components/baseComponents/Button";
import Div from "../../../../../components/baseComponents/Div";
import Row from "../../../../../components/baseComponents/Row";
import Dropdown from "../../../../../components/baseComponents/Dropdown";
import Input from "../../../../../components/baseComponents/Input";
import useCompanyFromParams from "../../../../utils/useCompanyFromParams";
import rentManagerResourceType from "../../../../../enums/rentManagerResourceType";
import { find } from "lodash";
import { retrieveResourceID } from "../../../../utils/rentManagerUtils";
import {
  RentManagerLocation,
  Option,
  GenericObject,
} from "../../../../../components/global/ModelInterfaces";
import ErrorMessage from "../../../../../components/baseComponents/ErrorMessage";

const StyledInstructions = styled(Div)`
  font-size: ${(props) => props.theme.font_size.bodyMD};
`;

const StyledResult = styled(Div)`
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;

const ResourceIDTool = () => {
  const { loadingCompanies, company } = useCompanyFromParams();
  const [selectedLocation, setSelectedLocation] =
    useState<RentManagerLocation | null>(null);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [resourceList, setResourceList] = useState<Array<GenericObject>>([]);

  const locationOptions = company.integration_info.locations.map(
    (location: RentManagerLocation) => {
      return {
        label: location.name,
        id: location.uuid,
      };
    }
  );

  const selectLocation = (option: Option) => {
    const location = find(company.integration_info.locations, (location) => {
      return location.uuid === option.id;
    });
    if (location) {
      setSelectedLocation(location);
    }
  };

  const handleSearch = () => {
    if (!selectedLocation || !email) {
      setError(
        "You must choose a location and enter an email before submitting."
      );
      return;
    }
    const successCallBack = (resourceList: Array<GenericObject>) => {
      setResourceList(resourceList);
      setLoading(false);
    };
    const failureCallback = () => {
      setError(
        "Could not find a matching resourceID for this Email and Location."
      );
      setResourceList([]);
      setLoading(false);
    };
    setLoading(true);
    setError("");
    setResourceList([]);
    retrieveResourceID(
      selectedLocation.uuid,
      email,
      successCallBack,
      failureCallback
    );
  };
  return (
    <Div>
      <Row>
        <StyledInstructions mb={{ default: 3 }}>
          Search for a Prospect or Tenant ID by email
        </StyledInstructions>
      </Row>
      <Row>
        <Div width={{ default: 1 }}>
          <Dropdown
            options={locationOptions}
            name="Location to Search"
            placeholder=""
            onClick={selectLocation}
            value={
              selectedLocation
                ? {
                    label: selectedLocation.name,
                    id: selectedLocation.uuid,
                  }
                : null
            }
          />
        </Div>
      </Row>
      <Row mb={{ default: 3 }}>
        <Div width={{ default: 1 }}>
          <Input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            label="Tenant or Prospect Email"
          />
        </Div>
      </Row>
      {error && (
        <Row justifyContent="center" mb={{ default: 3 }}>
          <ErrorMessage>{error}</ErrorMessage>
        </Row>
      )}
      {resourceList.length > 0 && (
        <Div width={{ default: 1 }} mb={{ default: 3 }}>
          <Row mb={{ default: 2 }}>
            <span>
              Here are the Tenants or Prospects that matched the email:
            </span>
          </Row>
          {resourceList.map((resource) => {
            if (resource.resource_status == rentManagerResourceType.PROSPECT) {
              return (
                <Row mb={{ default: 1 }}>
                  <StyledResult>
                    Name:{" "}
                    {resource.FirstName +
                      " " +
                      resource.LastName +
                      " • ProspectID: " +
                      resource.ProspectID +
                      " • Status: " +
                      resource.ProspectStatus}
                  </StyledResult>
                  <Row></Row>
                </Row>
              );
            } else if (
              resource.resource_status == rentManagerResourceType.TENANT
            ) {
              return (
                <Row mb={{ default: 1 }}>
                  <StyledResult>
                    Name:{" "}
                    {resource.FirstName +
                      " " +
                      resource.LastName +
                      " • TenantID: " +
                      resource.TenantID +
                      " • Status: " +
                      resource.Status}
                  </StyledResult>
                  <Row></Row>
                </Row>
              );
            }
          })}
        </Div>
      )}
      <Row>
        <Div width={{ default: 1 }}>
          <Button
            loading={loading}
            text="Search for Resource ID"
            onClick={handleSearch}
          />
        </Div>
      </Row>
    </Div>
  );
};
export default ResourceIDTool;
